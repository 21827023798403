const de = {
  translation: {
    case_studies_menu: 'Erfolgsgeschichten',
    wiki_menu: 'Wiki',
    vision_menu: 'Team',
    blog_menu: 'Blog',
    privacy_menu: 'DATENSCHUTZ',
    imprint_menu: 'IMPRESSUM',
    home_path: '/',
    case_studies_path: '/case-studies',
    wiki_path: '/wiki',
    vision_path: '/vision',
    blog_path: '/blog',
    show_case: 'Zu den Cases',
    show_vision: 'Unsere Vision',
    show_wiki: 'Zum Wiki',
    procom_invest_path: '/case-studies/procom-invest',
    procuratio_path: '/case-studies/procuratio',
    whitechart_path: '/case-studies/whitechart',
    privacy_path: '/privacy',
    imprint_path: '/imprint',
    get_call: 'Call Back Vereinbaren',
    landing_footage_title: 'Du willst ein Projekt umsetzen?',
    landing_footage_address_title: 'Kontakt',
    landing_footage_email_title: 'E-Mail an',
    show_project: 'Zum Projekt',
    show_privious: 'Voriges Projekt',
    show_next: 'Nächstes Projekt',
    footer_detail: 'ein Unternehmen von 20Scoops',
    thankyou: 'Danke für euer Vertrauen.',
    next: 'Nächstes',
    previous: 'Voriges',
    author: 'Autorin',
    homepage_title: '20IT Deine helfende Hand? Ist jetzt digital.',
    coming_soon: 'Demnächst verfügbar',
  },
};
export default de;
