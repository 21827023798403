import React from 'react';
import styled from 'styled-components';

// Utils
import AnimateComp from '../../../utils/animateWrapper';

const Background = styled.div`
  background-color: transparent;
  width: 100%;
  height: 348px;

  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    height: 100%;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  /* padding: 109px 209px; */
  margin: auto;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    display: block;
    width: 100%;
    max-width: 740px;
  }

  @media (max-width: ${({ theme }) => theme.device.laptopL}) {
    margin: auto;
  }

  @media (max-width: 768px) {
    padding: 0 30px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 50px 30px 0 30px;
  }
`;

const ContentTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.05em;
  color: #ffffff;
  width: 50%;
  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    ${({ theme }) => theme.typography.titleBar()};
    width: 100%;
    margin: 0 0 15px 0;
    max-width: none;
  }

  @media (max-width: ${({ theme }) => theme.device.tabletL}) {
    ${({ theme }) => theme.typography.titleBarMiddle()};
    font-size: 36px;
  }

  @media screen and (max-width: 767.9px) {
    ${({ theme }) => theme.typography.content3dMiddle()};
    font-weight: 700;
    line-height: 54px;
  }

  @media screen and (max-width: 767.9px) {
    ${({ theme }) => theme.typography.titleHighLight()};
    font-weight: 700;
    line-height: 54px;
  }

  @media (max-width: ${({ theme }) => theme.device.tabletL}) {
    text-align: start;
  }
`;

const DescWrapper = styled.span`
  ${({ theme }) => theme.typography.footerTextContent()};
  font-style: normal;
  letter-spacing: 0.05em;
  color: #ffffff;
  width: 50%;
  max-width: 588px;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.device.tabletL}) {
    white-space: initial;
  }
`;

const TextBackground = (props) => {
  return (
    <Background color={props.color}>
      <Wrapper>
        <AnimateComp animationType="fadeIn">
          {({ className }) => <ContentTitle className={className}>{props.title}</ContentTitle>}
        </AnimateComp>
        <AnimateComp animationType="fadeIn">
          {({ className }) => <DescWrapper className={className}>{props.description}</DescWrapper>}
        </AnimateComp>
      </Wrapper>
    </Background>
  );
};

export default TextBackground;
