import React from 'react';

export const PlayIcon = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48 88C25.92 88 8 70.08 8 48C8 25.92 25.92 8.00001 48 8.00001C70.08 8 88 25.92 88 48C88 70.08 70.08 88 48 88ZM44 64L60 48L44 32L44 64Z"
        fill="white"
        fill-opacity="0.74"
      />
    </svg>
  );
};
export const ArrowIcon = () => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 6.33994H14M14 6.33994L7.84211 1.33594M14 6.33994L7.84211 11.3439"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
