import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.textWhite};
  position: relative;
  width: 100%;
  margin: 0px auto;
  max-width: 1300px;
  padding: 15px 0;

  flex-direction: ${({ mirror }) => (!mirror ? 'row-reverse' : 'none')};

  @media screen and (max-width: 767.9px) {
    display: block;
    padding: 21px 0px 0px 0px;
  }

  @media (min-width: ${({ theme }) => theme.device.tablet}) and (max-width: ${({ theme }) =>
      theme.device.laptop}) {
    padding: 40px 0;
    justify-content: space-between;
  }
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => theme.typography.link()}
  color: ${({ theme }) => theme.color.textContent};
  place-self: center;
  

  animation-delay: 0.3s;
  animation-fill-mode: backwards;

  @media screen and (min-width: ${({ theme }) => theme.device.laptop}) {
    width:${({ mirror }) => (!mirror ? '55%' : '65%')};
    padding:  45px 0 45px 0;
    text-align-last: center;
  }

  @media screen and (max-width: 1024px) {
    width: 45%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 767.9px) {
    width: 100%;
    margin: 0;
    text-align: center;
  }

`;

export const DescWrapper = styled.div`
  color: ${({ theme }) => theme.color.textContent};
  width: 55%;
  align-self: center;
  padding: 30px 60px;
  max-width: 530px;

  animation-delay: 0.5s;
  animation-fill-mode: backwards;

  @media screen and (min-width: ${({ theme }) => theme.device.tabletL}) {
    width: 52%;
  }

  @media screen and (max-width: 767.9px) {
    max-width: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.device.laptop}) {
    width: ${({ mirror }) => (!mirror ? '45%' : '100%')};
    padding: 62px;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileS}) {
    ${({ theme }) => theme.typography.content3d()};
  }
`;

export const TopicsWrapper = styled.div`
  ${({ theme }) => theme.typography.titleContent()}
  margin: 12px 0;

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    margin: 0 0 12px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    ${({ theme }) => theme.typography.description()}
    font-weight: bold;
  }
  @media screen and (max-width: 767.9px) {
    margin: 42px 0 12px 0;
  }

  @media screen and (max-width: 425px) {
    margin: 0 0 12px 0;
  }
`;

export const DetailWrapper = styled.div`
  margin: 0 0 1rem 0;
  ${({ theme }) => theme.typography.footerTextContent()};

  a {
    color: ${({ theme }) => theme.color.titleLightBlue};
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileM}) {
    white-space: inherit;
  }
`;
export const TextBoxWrapper = styled.div`
  color: ${({ theme }) => theme.color.textWhite};
  width: 100%;
  align-self: center;

  @media screen and (max-width: ${({ theme }) => theme.device.tabletL}) {
    padding: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileS}) {
    padding: 0 3% 15px 5%;
  }
`;
export const QuoteWrapper = styled.div`
  align-items: center;
  line-height: 30px;
  margin-top: 30px;
`;
export const QuoteTitle = styled.div`
  ${({ theme }) => theme.typography.button()}
  margin-bottom: 20px;
  font-weight: 700;
`;

export const QuoteText = styled.span`
  ${({ theme }) => theme.typography.link()}
  font-style: italic;
  font-weight: 400;
`;
