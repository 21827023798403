import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';

// Utils
import { BannerWrapperCase } from '../../../templates/Case';

const fadeIn = keyframes`
   0%   { opacity: 0; }
  100% { opacity: 1; }
`;

export const TextWrapper = styled.div`
  animation-name: ${fadeIn};
  animation-duration: ${({ duration }) => duration}s;
  animation-fill-mode: forwards;
  display: flex;
  color: ${({ theme }) => theme.color.textWhite};
  margin: 10px auto;

  @media screen and (max-width: ${({ theme }) => theme.device.tabletL}) {
    display: block;
    padding: 0 5%;
  }
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => theme.typography.largeCaseTitle()};
  min-width: fit-content;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.device.mobileS}) and (max-width: ${({ theme }) =>
      theme.device.tablet}) {
    ${({ theme }) => theme.typography.contentTitle()};
  }

  @media (min-width: ${({ theme }) => theme.device.tablet}) and (max-width: ${({ theme }) =>
      theme.device.laptop}) {
    ${({ theme }) => theme.typography.subHeadline()};
  }

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    width: 100%;
    margin: 20px 0 0 0;
  }
`;

export const TitleDescription = styled.div`
  ${({ theme }) => theme.typography.descriptionTitle()};
  line-height: 32px;
  width: 100%;
  place-self: flex-end;
  margin: 0 10px;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    line-height: 25px;
    width: 100%;
    margin: 0 0 33px 0;
  }
`;

export const ImageStyle = styled.img`
  height: 316px;
  inline-size: fit-content;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const LinkStyle = styled(Link)`
  color: white;
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    display: block;
  }
`;

const Content = (props) => {
  const enter = () => {
    props.setHoverIndex(props.index);
  };
  const leave = () => {
    props.setHoverIndex(0);
  };

  return (
    <BannerWrapperCase
      onMouseEnter={() => enter()}
      onMouseLeave={() => leave()}
      hoverIndex={props.hoverIndex}
      index={props.index}
    >
      <TextWrapper duration={props.index + 1}>
        <LinkStyle to={props.linkTo} id={props.title}>
          <ImageStyle src={props.background} className="picture" />
          <TitleWrapper>{props.title}</TitleWrapper>
          <TitleDescription>{props.description}</TitleDescription>
        </LinkStyle>
      </TextWrapper>
    </BannerWrapperCase>
  );
};

export default Content;
