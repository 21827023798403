import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 10% 15% 7% 17%;
  color: ${({ theme }) => theme.color.textWhite};
  a {
    color: ${({ theme }) => theme.color.cardLightBlue};
  }

  @media (max-width: 768px) {
    padding: 8rem 10% 10%;
  }
`;
const Title = styled.div`
  ${({ theme }) => theme.typography.footerSubHeadline()}
  margin-bottom: 20px;
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.titleBar()}
  }
`;

const SubTitle = styled.div`
  margin: 1.6em 0 1.6em;
  ${({ theme }) => theme.typography.link()};
  line-height: 27px;
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.formHeadline()}
  }
`;
const Address = styled.div`
  ${({ theme }) => theme.typography.link()};
  line-height: 27px;
  white-space: pre-line;
  margin-top: 40px;
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.formHeadline()}
  }
`;

const Director = styled.div`
  white-space: pre-line;
  ${({ theme }) => theme.typography.cardHover()};
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.formHeadline()}
  }
`;

const TextLink = styled.div`
  ${({ theme }) => theme.typography.cardHover()};
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.formHeadline()}
  }
`;

const ButtonLink = styled.a`
  ${({ theme }) => theme.typography.link()};
  color: ${({ theme }) => theme.color.textLink};
  background-color: none;
  transition: color 0.1s;
`;

const ImprintWrapper = (props) => {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <SubTitle>{props.detail}</SubTitle>
      <Address>{props.address}</Address>
      <Director>{props.director}</Director>
      <TextLink>
        {props.titleEmail}
        <ButtonLink href={`mailto:${props.textEmail}`}> {props.textEmail}</ButtonLink>
      </TextLink>
      <TextLink>
        {props.titleWeb}
        <ButtonLink href="https://20it.de/" target="_blank" rel="noopener noreferrer">
          {' '}
          {props.textWeb}
        </ButtonLink>
      </TextLink>
    </Wrapper>
  );
};

export default ImprintWrapper;
