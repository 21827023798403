import React, { Suspense, useState } from 'react';
import styled from 'styled-components';
import Footer from '../../components/layout/footer';
import WhatWeDoGraphics from '../../images/what-we-do.webp';
import Lottie from 'lottie-react';
import loadingAnimation from './assets/loading-animation.json';

const Wrapper = styled.div`
  padding: 1.9rem 0 0 0;
  position: relative;
  @media screen and (min-width: 426px) {
    background: ${({ theme }) => theme.color.grayBlack};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    background: ${({ theme }) => theme.color.grayBlack};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    width: 100%;
    justify-content: center;
  }
`;

const ImageWrapper = styled.img`
  width: 100%;
  min-height: 100%;
  background: ${({ theme }) => theme.color.grayBlack};
`;

const LoadingWrapper = styled.div`
  background: ${({ theme }) => theme.color.grayBlack};
  width: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LayoutWrapper = styled.div`
  background: ${({ theme }) => theme.color.grayBlack};
`;

const WhatWeDoPage = () => {
  const [loading, setLoading] = useState(true);

  if (typeof window === 'undefined')
    return (
      <div
        css={`
          width: 100vw;
          height: 100vh;
          background: #303030;
        `}
      />
    );

  return (
    <Suspense
      fallback={
        <div
          css={`
            width: 100vw;
            height: 100vh;
            background: #303030;
          `}
        />
      }
    >
      <Wrapper id="content">
        <div
          style={{ color: 'black', background: 'white', marginTop: '58px', marginBottom: '-22px' }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            {loading ? (
              <LoadingWrapper>
                <div style={{ width: '72px', height: '72px' }}>
                  <Lottie width={20} height={20} animationData={loadingAnimation} loop={true} />
                </div>
              </LoadingWrapper>
            ) : null}
            <LayoutWrapper>
              <ImageWrapper
                src={WhatWeDoGraphics}
                alt="what-we-do-graphics"
                loading="lazy"
                onLoad={() => {
                  setLoading(false);
                }}
              />
            </LayoutWrapper>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </Suspense>
  );
};

export default WhatWeDoPage;
