import React from 'react';
import styled from 'styled-components';

// Components
import SEO from '../components/seo';
import BlogCard from '../components/blog/card';
import { ButtonText } from '../components/button';

// data
import { graphql } from 'gatsby';

// utils
import withTranslate from '../utils/withTransWrapper';
import AnimateComp from '../utils/animateWrapper';
import linkResolver from '../utils/linkResolver';
import Footer from '../components/layout/footer';

const Wrapper = styled.div`
  background-color: #303030;
  position: relative;
  color: ${({ theme }) => theme.color.textWhite};
`;

const DetailPage = styled.div`
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 8%;
  }
`;

const ContentPage = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  max-width: 1200px;
  width: 100%;
  grid-gap: 66px;
`;

const PostNone = styled.div`
${({ theme }) => theme.typography.button()}
  color: ${({ theme }) => theme.color.textMenu};
  text-align: center;
`;

const TitleWrapper = styled.div`
  margin: 0 auto;
  padding-top: 1.5em;
  width: 100%;

  ${({ theme }) => theme.typography.largeTitle()};
  white-space: pre-line;
  h1 {
    ${({ theme }) => theme.typography.largeTitle()};
    margin: 0 auto;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.contentTitle()};
    h1 {
      ${({ theme }) => theme.typography.contentTitle()};
      padding-top: 0.5em;
    }
  }

  @media (min-width: ${({ theme }) => theme.device.mobileL}) and (max-width: ${({ theme }) =>
      theme.device.laptop}) {
    padding-top: 1em;
    h1 {
      ${({ theme }) => theme.typography.subHeadline()};
      line-height: 42px;
    }
  }

  @media (min-width: ${({ theme }) => theme.device.laptop}) and (max-width: ${({ theme }) =>
      theme.device.laptopL}) {
    padding-top: 1em;
  }
`;

const TagDesc = styled.div`
  padding-top: 5px;
  margin: 0 0 50px 0;
  font-size: 18px;
  font-weight: 700;
`;

const FadeStyle = styled.div`
  position: absolute;
  width: 2257px;
  height: 1650px;
  left: -683px;
  top: -647px;

  background: radial-gradient(
    38.84% 50% at 50% 50%,
    rgba(161, 52, 234, 0.34) 0%,
    rgba(161, 52, 234, 0) 100%
  );
`;

const BlogTag = ({ data, location }) => {
  const prismicBlogpage = data.prismicBlogPage;
  const prismicBlogPost = data.allPrismicBlogPost.edges;

  if (!prismicBlogPost) return null;

  const document = prismicBlogpage;

  const { pathname } = location;

  const TagTitle = pathname.split('/')[3];
  return (
    <AnimateComp animationType="fadeInAndDownToTop">
      {({ className }) => (
        <>
          <Wrapper className={className}>
            <SEO title="Blogs" />
            <FadeStyle />
            <DetailPage>
              <TitleWrapper>{TagTitle}</TitleWrapper>
              <TagDesc>
                Tags &nbsp;&nbsp;
                <ButtonText
                  to={linkResolver({
                    type: document.type,
                  })}
                  underline="underline"
                >
                  Clear
                </ButtonText>
              </TagDesc>
              <ContentPage>
                {prismicBlogPost.length > 0 ? (
                  prismicBlogPost.map((blog, i) => (
                    <BlogCard
                      key={`${i}-${blog}`}
                      category={blog.node.data.blog_category.raw}
                      title={blog.node.data.blog_title.raw}
                      description={blog.node.data.blog_preview_description.raw}
                      thumbnail={blog.node.data.blog_hero_image}
                      meta={{
                        type: blog.node.type,
                        uid: blog.node.uid,
                      }}
                    />
                  ))
                ) : (
                  <PostNone>This publication does not have any stories yet.</PostNone>
                )}
              </ContentPage>
            </DetailPage>
          </Wrapper>
          <Footer />
        </>
      )}
    </AnimateComp>
  );
};

export default withTranslate(BlogTag);

export const query = graphql`
  query {
    prismicBlogPage {
      type
      id
      data {
        banner_title {
          raw
        }
      }
    }
    allPrismicBlogPost(sort: { fields: data___blog_post_date, order: DESC }) {
      edges {
        node {
          id
          type
          uid
          tags
          data {
            blog_title {
              raw
            }
            blog_preview_description {
              raw
            }
            blog_post_date
            blog_category {
              raw
            }
            blog_image {
              fluid(maxWidth: 2048) {
                src
              }
            }
            blog_hero_image {
              fluid(maxWidth: 2048) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
