import React from 'react';
import Layout from '../components/layout';
import usePreviewData from './usePreviewData';
const withTranslate = (WrapperComponent, ignorePreviewData) => {
  const LayoutWrapper = ({ data, location, pageContext = {} }) => {
    const previewData = usePreviewData(data);

    if (!previewData && !ignorePreviewData) return null;

    const page = Object.keys(previewData ?? {})?.[0];
    const meta = {
      type: previewData?.[page]?.type,
    };

    return (
      <Layout activeDocMeta={meta} location={location}>
        <WrapperComponent data={previewData} location={location} pageContext={pageContext} />
      </Layout>
    );
  };

  return LayoutWrapper;
};

export default withTranslate;
