import React, { Suspense } from 'react';
import { useMedia } from 'react-use';
import { lazy } from '@loadable/component';

const Desktop = lazy(() => import('../homepage/desktop'));
const Mobile = lazy(() => import('../homepage/mobile'));

const Homepage = ({ data }) => {
  const isLessThan767 = useMedia(`(max-width: 767px)`, true);
  const isLandScape = useMedia(`(orientation: landscape)`, true);
  const isLessThan834 = useMedia(`(max-width: 834px)`, true);
  const isMobile = isLessThan767 || (isLessThan834 && isLandScape);

  if (typeof window === 'undefined')
    return (
      <div
        css={`
          width: 100vw;
          height: 100vh;
          background: #303030;
        `}
      />
    );

  return (
    <>
      {isMobile ? (
        <Suspense
          fallback={
            <div
              css={`
                width: 100vw;
                height: 100vh;
                background: #303030;
              `}
            />
          }
        >
          <Mobile data={data} />
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <div
              css={`
                width: 100vw;
                height: 100vh;
                background: #303030;
              `}
            />
          }
        >
          <Desktop data={data} />
        </Suspense>
      )}
    </>
  );
};

export default Homepage;
