import {
  createFontStyle,
  createFontSizeStyle,
  createFontWeightStyle,
  createLineHeight,
  createLetterSpacing,
} from './utils/typography';

const typography = {
  largeBanner: createFontStyle(
    createFontSizeStyle(96),
    createFontWeightStyle('700'),
    createLineHeight('144px'),
  ),
  largeTitle: createFontStyle(
    createFontSizeStyle(64),
    createFontWeightStyle('600'),
    createLineHeight('96px'),
  ),
  caption: createFontStyle(
    createFontSizeStyle(22),
    createFontWeightStyle('500'),
    createLineHeight('36px'),
  ),
  title: createFontStyle(createFontSizeStyle(22), createFontWeightStyle('500')),
  captionHighLight: createFontStyle(
    createFontSizeStyle(14),
    createFontWeightStyle('bold'),
    createLineHeight('19px'),
    createLetterSpacing('1px'),
  ),
  titleHighLight: createFontStyle(createFontSizeStyle(24), createFontWeightStyle('400')),
  link: createFontStyle(
    createFontSizeStyle(18),
    createFontWeightStyle('500'),
    createLineHeight('24px'),
  ),
  button: createFontStyle(
    createFontSizeStyle(18),
    createFontWeightStyle('600'),
    createLineHeight('16px'),
  ),
  subHeadline: createFontStyle(
    createFontSizeStyle(36),
    createFontWeightStyle(700),
    createLineHeight('33px'),
  ),
  formHeadline: createFontStyle(
    createFontSizeStyle(16),
    createFontWeightStyle('500'),
    createLineHeight('24px'),
    createLetterSpacing('1px'),
  ),
  description: createFontStyle(
    createFontSizeStyle(24),
    createFontWeightStyle('500'),
    createLineHeight('1.7'),
  ),
  footerContent: createFontStyle(createFontSizeStyle(11), createFontWeightStyle('400')),
  footerSubHeadline: createFontStyle(
    createFontSizeStyle(48),
    createFontWeightStyle(700),
    createLineHeight('72px'),
  ),
  footerTextContent: createFontStyle(
    createFontSizeStyle(16),
    createFontWeightStyle('400'),
    createLineHeight('24px'),
  ),
  subTile: createFontStyle(
    createFontSizeStyle(22),
    createFontWeightStyle('500'),
    createLineHeight('21px'),
  ),
  name: createFontStyle(
    createFontSizeStyle(36),
    createFontWeightStyle('bold'),
    createLineHeight('32px'),
  ),
  contentTitle: createFontStyle(
    createFontSizeStyle(32),
    createFontWeightStyle('600'),
    createLineHeight('42px'),
  ),
  footageTitle: createFontStyle(
    createFontSizeStyle(44),
    createFontWeightStyle('600'),
    createLineHeight('50px'),
  ),
  footageDetail: createFontStyle(
    createFontSizeStyle(18),
    createFontWeightStyle('400'),
    createLineHeight('27px'),
  ),
  cardHover: createFontStyle(
    createFontSizeStyle(20),
    createFontWeightStyle('500'),
    createLineHeight('1.7rem'),
  ),
  customerContact: createFontStyle(createFontSizeStyle(12), createFontWeightStyle(400)),
  contactDetail: createFontStyle(
    createFontSizeStyle(26),
    createFontWeightStyle(700),
    createLineHeight('60px'),
  ),
  content3d: createFontStyle(
    createFontSizeStyle(14),
    createFontWeightStyle(500),
    createLineHeight('24px'),
  ),
  content3dBig: createFontStyle(
    createFontSizeStyle(24),
    createFontWeightStyle(400),
    createLineHeight('30px'),
  ),
  content3dMiddle: createFontStyle(
    createFontSizeStyle(28),
    createFontWeightStyle(400),
    createLineHeight('33px'),
  ),
  content3dSmall: createFontStyle(createFontSizeStyle(9), createFontWeightStyle(400)),
  bannerDescription: createFontStyle(createFontSizeStyle(15), createFontWeightStyle(400)),
  descriptionTitle: createFontStyle(
    createFontSizeStyle(18),
    createFontWeightStyle('500'),
    createLineHeight('64px'),
  ),
  smallTitle: createFontStyle(
    createFontSizeStyle(14),
    createFontWeightStyle(700),
    createLineHeight('21px'),
  ),
  smallDesc: createFontStyle(
    createFontSizeStyle(14),
    createFontWeightStyle(400),
    createLineHeight('21px'),
  ),
  bigTitleBanner: createFontStyle(
    createFontSizeStyle(72),
    createFontWeightStyle(700),
    createLineHeight('108px'),
  ),
  titleContent: createFontStyle(
    createFontSizeStyle(24),
    createFontWeightStyle(700),
    createLineHeight('36px'),
  ),
  titleFootage: createFontStyle(
    createFontSizeStyle(24),
    createFontWeightStyle(400),
    createLineHeight('36px'),
  ),
  titleBar: createFontStyle(
    createFontSizeStyle(36),
    createFontWeightStyle(700),
    createLineHeight('54px'),
  ),
  titleBarMiddle: createFontStyle(
    createFontSizeStyle(30),
    createFontWeightStyle(700),
    createLineHeight('54px'),
  ),
  descBlogMiddle: createFontStyle(
    createFontSizeStyle(21),
    createFontWeightStyle(400),
    createLineHeight('30.55px'),
  ),
  BigHeader: createFontStyle(
    createFontSizeStyle(48),
    createFontWeightStyle(600),
    createLineHeight('67.2px'),
  ),
  largeCaseTitle: createFontStyle(
    createFontSizeStyle(64),
    createFontWeightStyle('600'),
    createLineHeight('64px'),
  ),
  buttonBig: createFontStyle(
    createFontSizeStyle(18),
    createFontWeightStyle('700'),
    createLineHeight('21px'),
  ),
  largeFont: createFontStyle(
    createFontSizeStyle(56),
    createFontWeightStyle('600'),
    createLineHeight('84px'),
  ),
};

export default typography;
