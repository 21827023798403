/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './global.css';
import LogRocket from 'logrocket';

export const onClientEntry = () => {
  if (!process.env.GATSBY_NETLIFY) {
    LogRocket.init('20it/20it');
  }
  return null;
};
