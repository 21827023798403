import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

// Components
import TagComponent from '../components/blog/tag';
import Pagination from '../components/blog/pagination';

// data
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// utils
import withTranslate from '../utils/withTransWrapper';
import Footer from '../components/layout/footer';
import SEO from '../components/seo';

const Wrapper = styled.div`
  background-color: #303030;
`;

const PictureStyle = styled(Img)`
  width: 100% !important;
  height: 500px !important;
  object-fit: cover !important;
  object-position: center !important;
`;

const OverlayFade = styled.div`
  height: 300px;
  z-index: 3;
  width: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0) 100%);
`;

const PictureDetailStyle = styled.img`
  width: 1000px;
  height: auto;
  object-fit: cover;
`;

const DetailPage = styled.div`
  color: ${({ theme }) => theme.color.textWhite};
  max-width: 1400px;
  margin: 70px auto 0 auto;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 36px auto 0 auto;
  }
`;
const TitleDetailPage = styled.div`
  ${({ theme }) => theme.typography.titleFootage()};
  font-weight: 600;
  line-height: 33.6px;
  margin: 0 0 27px 0;
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.content3dBig()};
    padding: 0 25px 25px 25px;
  }
`;
const DescriptionDetailPage = styled.div`
  ${({ theme }) => theme.typography.descBlogMiddle()};
  margin: 15px 0 30px 0;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.footageDetail()};
    padding: 0 15px 0 30px;
  }
  a {
    color: ${({ theme }) => theme.color.cardLightBlue};
  }
`;

const BlogBody = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    padding: 0 55px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 0;
  }
`;

const PictureLayout = styled.div`
  display: flex;
  justify-content: center;
  margin: 41px 0;
`;

const TagsTitle = styled.span`
  ${({ theme }) => theme.typography.button()};
`;

const Hr = styled.hr`
  background: #757575;
  margin-top: 15px;
`;
const BottomPage = styled.div`
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 0 25px;
  }
`;

const BlogPost = ({ data, pageContext }) => {
  const prismicBlogPosts = data.prismicBlogPost;
  const prismicBlogPage = data.prismicBlogPage;

  if (!prismicBlogPosts) return null;
  const documentPost = prismicBlogPosts;
  const DataBlogPost = {
    image_banner: documentPost.data.blog_hero_image.fluid,
    title: documentPost.data.blog_title.raw,
    description_first: documentPost.data.blog_description_first_path.raw,
    description_second: documentPost.data.blog_description_second_path.raw,
    image_detail: documentPost.data.blog_image.fluid,
    date: documentPost.data.blog_post_date,
  };

  // TODO: page navigation
  const next = pageContext.next
    ? {
        url: `/blog/${pageContext.next.uid}`,
        title: pageContext.next.data.blog_title.raw,
        date: pageContext.next.data.blog_post_date,
        image: pageContext.next.data.blog_hero_image.fluid?.src,
      }
    : null;
  const prev = pageContext.prev
    ? {
        url: `/blog/${pageContext.prev.uid}`,
        title: pageContext.prev.data.blog_title.raw,
        date: pageContext.prev.data.blog_post_date,
        image: pageContext.prev.data.blog_hero_image.fluid?.src,
      }
    : null;

  return (
    <>
      <SEO title={RichText.asText(DataBlogPost.title)} />
      <Wrapper>
        <OverlayFade />
        <PictureStyle fluid={DataBlogPost.image_banner} />
        <BlogBody>
          <DetailPage>
            <TitleDetailPage>{RichText.render(DataBlogPost.title)}</TitleDetailPage>
            <DescriptionDetailPage>
              <RichText
                render={DataBlogPost.description_first}
                elements={{
                  hyperlink: function a({ children, data }) {
                    return (
                      <a href={data.url} rel="nofollow" target="blank">
                        {children}
                      </a>
                    );
                  },
                }}
              />
            </DescriptionDetailPage>
            <PictureLayout>
              <PictureDetailStyle src={DataBlogPost.image_detail?.src} />
            </PictureLayout>
            <DescriptionDetailPage>
              <RichText
                render={DataBlogPost.description_second}
                elements={{
                  hyperlink: function a({ children, data }) {
                    return (
                      <a href={data.url} rel="nofollow" target="blank">
                        {children}
                      </a>
                    );
                  },
                }}
              />
            </DescriptionDetailPage>
            <BottomPage>
              <TagsTitle>Tags</TagsTitle>
              <TagComponent blogPost={prismicBlogPosts} blogPage={prismicBlogPage} url={true} />
              <Hr />
              <TitleDetailPage>Das könnte dich auch interessieren</TitleDetailPage>
              <Pagination prev={prev} next={next} />
            </BottomPage>
          </DetailPage>
        </BlogBody>
        <Footer />
      </Wrapper>
    </>
  );
};

export default withTranslate(BlogPost);

export const query = graphql`
  query($uid: String) {
    prismicBlogPost(uid: { eq: $uid }) {
      tags
      uid
      type
      data {
        blog_hero_image {
          fluid(maxWidth: 2048) {
            src
          }
        }
        blog_image {
          fluid {
            src
          }
        }
        blog_post_date(formatString: "MM/DD/YYYY")
        blog_title {
          raw
        }
        blog_description_first_path {
          raw
        }
        blog_description_second_path {
          raw
        }
      }
    }
    prismicBlogPage {
      type
      id
      data {
        banner_title {
          raw
        }
      }
    }
  }
`;
