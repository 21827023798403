import React, { useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Components
import SEO from '../components/seo';
import BlogCard from '../components/blog/card';
import TagComponent from '../components/blog/tag';
import { ButtonTextNoneLink } from '../components/button';
import Empty from '../images/empty.png';
import Footer from '../components/layout/footer';

// data
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';

// utils
import withTranslate from '../utils/withTransWrapper';
import { useHandleLanguage } from '../contexts/language.context';
import BannerLatestNews from '../components/blog/bannerLatestNews';
import { useMedia } from '../hook/index.ts';
import AnimateComp from '../utils/animateWrapper';
import { TagsFunc } from '../contexts/tag.context';

const fadeInWrapper = keyframes`
0%{
opacity:0;
}
100%{
opacity:1;
}
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #303030;
  padding: 3em;
  @media (max-width: 767px) {
    padding: 2em;
  }
  @media (max-width: 568px) {
    padding: 1em;
  }
  @media (max-width: 668px) {
    padding: 2em;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 0em;
  }
`;

const DetailPage = styled.div`
  animation-name: ${fadeInWrapper};
  animation-duration: 1s;
  opacity: 1;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 59px;
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding-top: 40px;
  }
`;

const PostNone = styled.div`
  ${({ theme }) => theme.typography.button()}
  color: ${({ theme }) => theme.color.textLink};
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    flex-flow: row wrap;
  }

`;

const SpanWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    margin-top: 40px;
  }
`;

const ImageWrapper = styled.div`
  z-index: -1;
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
    img {
      min-width: 200px;
    }
  }
  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    img {
      max-width: 500px;
      position: absolute;
      top: 20%;
      right: 20%;
      z-index: -1;
    }
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 1.5em;
  width: 100%;
  color: white;
  white-space: pre-line;
  h1 {
    ${({ theme }) => theme.typography.BigHeader()};
    margin: 0 auto;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.contentTitle()};
    padding: 122px 1em 0 1em;
    h1 {
      ${({ theme }) => theme.typography.contentTitle()};
      font-size: 40px;
      padding-top: 0.5em;
    }
  }
  @media (min-width: ${({ theme }) => theme.device.laptop}) and (max-width: ${({ theme }) =>
      theme.device.laptopL}) {
    padding-top: 1em;
  }
  @media (max-width: 425px) {
    padding: 82px 1em 0 1em;
  }
`;

const LoadmoreWrap = styled.div`
  width: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 45px 0;
  &:hover {
    transition: all 150ms ease-out;
    span {
      transform: translateX(0px);
      opacity: 1;
      transition: transform 150ms ease-out;
    }
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 50px 0;
  }
`;
const RowDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  min-height: 500px;
  opacity: 1;
  padding-top: 40px;
`;

const fadeInCircle = keyframes`
  0% {
    transform: scale(.3);opacity:0.3;
  }
  100% {
    transform: scale(1.5);opacity:1;
  }
`;

const FadeStyle = styled.div`
  animation-name: ${fadeInCircle};
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: absolute;
  width: 1940px;
  height: 1650px;
  left: -660px;
  top: -847px;
  background: radial-gradient(
    38.84% 50% at 50% 50%,
    rgba(161, 52, 234, 0.34) 0%,
    rgba(161, 52, 234, 0) 100%
  );
  @media (max-width: 834px) {
    width: 1943px;
    height: 1350px;
    left: -1000px;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    left: -1250px;
  }
`;

const fadeLayout = keyframes`
0%{opacity:0;}
50%{opacity:0;}
100%{opacity:1;}
`;

const Layout = styled.div`
  animation-name: ${fadeLayout};
  animation-duration: 1000ms;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 329px));
  width: 100%;
  grid-gap: 66px;
  margin-bottom: 48px;
  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
  }
  @media (max-width: 768px) {
    grid-gap: 60px;
  }
  @media (max-width: 667px) {
    grid-gap: 33px;
  }
  @media (max-width: 568px) {
    grid-gap: 40px;
    grid-template-columns: 1fr;
  }
`;

const LayoutTags = styled.div`
  padding-bottom: 40px;
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 1em 2em 40px 2em;
  }
`;

const Blog = ({ data }) => {
  const [page, setPage] = useState(1);
  const { t } = useHandleLanguage();

  const prismicBlogpage = data.prismicBlogPage;
  const prismicBlogPost = data.allPrismicBlogPost.edges;

  const checkSize = useMedia(`(max-width: 1024px)`);

  const { tagsState, setTagsState } = useContext(TagsFunc);
  const [dataSubContent, setDataSubContent] = useState([]);

  useEffect(() => {
    const dataFilter = prismicBlogPost.filter((data) =>
      data.node.tags.find((d) => d === tagsState),
    );
    const mainPageBlog = prismicBlogPost;
    setDataSubContent(tagsState === 'main' ? mainPageBlog : dataFilter);
  }, [prismicBlogPost, tagsState]);

  if (!prismicBlogpage) return null;

  const bannerContent = {
    title: prismicBlogpage.data.banner_title.raw,
    meta: {
      type: prismicBlogpage.type,
    },
  };

  const chunks = checkSize ? 5 : 7;
  const renderBlog = () => {
    let paginated = [];
    paginated = Array.from(prismicBlogPost).splice(0, page * chunks);

    const dataHighlight = paginated[0];
    const dataBigBanner = paginated.filter((data) => data !== dataHighlight);

    return (
      <AnimateComp animationType="fadeIn">
        {({ className }) => (
          <RowDetail className={className} id="rowDetailWrapper">
            {tagsState === 'main' ? (
              <BannerLatestNews
                title={paginated[0].node.data.blog_title.raw}
                description={paginated[0].node.data.blog_preview_description.raw}
                thumbnail={paginated[0].node.data.blog_hero_image}
                meta={{
                  type: paginated[0].node.type,
                  uid: paginated[0].node.uid,
                }}
              />
            ) : null}

            <Layout className={className}>
              {tagsState !== 'main'
                ? dataSubContent.map((group, index) => (
                    <BlogCard
                      key={index}
                      title={group.node.data.blog_title.raw}
                      description={group.node.data.blog_preview_description.raw}
                      thumbnail={group.node.data.blog_hero_image}
                      meta={{
                        type: group.node.type,
                        uid: group.node.uid,
                      }}
                    />
                  ))
                : dataBigBanner.map((group, index) => (
                    <BlogCard
                      key={index}
                      title={group.node.data.blog_title.raw}
                      description={group.node.data.blog_preview_description.raw}
                      thumbnail={group.node.data.blog_hero_image}
                      meta={{
                        type: group.node.type,
                        uid: group.node.uid,
                      }}
                    />
                  ))}
            </Layout>
            {paginated.length < (dataSubContent.length || prismicBlogPost.length) &&
              tagsState === 'main' && (
                <LoadmoreWrap>
                  <ButtonTextNoneLink onClick={() => onLoad()}>
                    <span>+</span> LOAD MORE
                  </ButtonTextNoneLink>
                </LoadmoreWrap>
              )}
          </RowDetail>
        )}
      </AnimateComp>
    );
  };

  const handleChangeTag = (tag) => {
    const url = new URL(window.location.href);
    if (tag) {
      url.searchParams.set('tags', tag);
      window.history.pushState({}, '', url.href);
    } else {
      url.searchParams.set('tags', 'main');
      window.history.pushState({}, '', url.href);
    }
    setTagsState(tag);
  };

  const onLoad = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Wrapper>
        <FadeStyle />
        <DetailPage>
          <SEO title="Blog" />
          <TitleWrapper>{RichText.render(bannerContent.title)}</TitleWrapper>
          <LayoutTags>
            <TagComponent
              blogPost={prismicBlogPost}
              blogPage={bannerContent.meta}
              handleChangeTag={handleChangeTag}
              tagsState={tagsState}
              setTagsState={setTagsState}
            />
          </LayoutTags>

          {prismicBlogPost.length > 0 ? (
            <>{renderBlog()}</>
          ) : (
            <PostNone>
              <SpanWrapper>
                <span>{t('coming_soon')}</span>
              </SpanWrapper>

              <ImageWrapper>
                <img src={Empty} alt="empty data" />
              </ImageWrapper>
            </PostNone>
          )}
        </DetailPage>
      </Wrapper>
      <Footer />
    </>
  );
};

export default withTranslate(Blog);

export const query = graphql`
  query {
    prismicBlogPage {
      type
      id
      data {
        banner_title {
          raw
        }
      }
    }
    allPrismicBlogPost(sort: { fields: data___blog_post_date, order: DESC }) {
      edges {
        node {
          id
          type
          uid
          tags
          data {
            blog_title {
              raw
            }
            blog_preview_description {
              raw
            }
            blog_image {
              fluid {
                src
              }
            }
            blog_hero_image {
              fluid(maxWidth: 2048) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
