import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonText } from '../button';

import { RichText } from 'prismic-reactjs';

const PageControl = styled.div`
  height: auto;
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 15px 0 166px 0;

  align-content: center;
`;
const ContentDetail = styled.div`
  display: flex;
`;
const Detail = styled.div`
  max-width: 302px;
  overflow: hidden;
`;
const SubTitleDetail = styled.div`
  height: 51px;
  /* stylelint-disable */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  h5 {
    line-height: 1.3 !important;
  }
`;
const Date = styled.div`
  padding: 15px 0;
`;

const ImageStyle = styled.img`
  width: 91px;
  height: 91px;
  object-fit: cover;
`;

const Prev = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;

  h5 {
    color: ${({ theme }) => theme.color.textWhite};
    font-weight: 500;
    margin: 0 15px 0 40px;
  }

  span {
    vertical-align: middle;
    margin-left: 20px;
  }

  svg {
    fill: ${({ theme }) => theme.color.textMenu};
    vertical-align: middle;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
    padding: 15px 0;
  }
`;

const Pagination = ({ prev, next }) => {
  return (
    <PageControl>
      <>
        {prev !== null ? (
          <Prev>
            <ButtonText to={prev.url}>
              <ContentDetail>
                <ImageStyle src={prev.image} />
                <Detail>
                  <SubTitleDetail>
                    <h5
                      css={`
                        line-height: 1.3 !important;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      `}
                    >
                      {RichText.asText(prev.title)}
                    </h5>
                  </SubTitleDetail>
                  <Date>
                    <h5>{prev.date}</h5>
                  </Date>
                </Detail>
              </ContentDetail>
            </ButtonText>
          </Prev>
        ) : null}
      </>
      <>
        {next !== null ? (
          <Prev>
            <ButtonText to={next.url}>
              <ContentDetail>
                <ImageStyle src={next.image} />
                <Detail>
                  <SubTitleDetail>
                    <h5
                      css={`
                        line-height: 1.3 !important;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      `}
                    >
                      {RichText.asText(next.title)}
                    </h5>
                  </SubTitleDetail>
                  <Date>
                    <h5>{next.date}</h5>
                  </Date>
                </Detail>
              </ContentDetail>
            </ButtonText>
          </Prev>
        ) : null}
      </>
    </PageControl>
  );
};

Pagination.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};
export default Pagination;
