import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import Banner from '../components/subcase/banner';
import TextBackground from '../components/subcase/background';
import Content from '../components/subcase/content';
import Footage from '../components/subcase/footage';

// data
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';

// utils
import withTranslate from '../utils/withTransWrapper';
import Footer from '../components/layout/footer';
import SEO from '../components/seo';

import Img from 'gatsby-image';
import { useMedia } from '../hook/index.ts';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    padding: 0px;
  }
`;

const CenterBar = styled.div`
  max-width: 700px;
  margin: auto;
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.color.textWhite};

  @media screen and (max-width: 767.9px) {
    padding: 0 30px 15px 30px;
  }
`;

const ContentStyle = styled.div`
  position: relative;
  background-color: transparent;
`;

const DetailText = styled.div`
  font-style: italic;

  &.mobile {
    display: none;
  }

  @media (max-width: 767.9px) {
    &.pc {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

const Space = styled.div`
  ${({ theme }) => theme.typography.footerTextContent()};

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    &.top {
      margin: 20px 0 0 0;
      padding: 25px 0px 0px 0px;
    }
    &.bot {
      margin: 0 0 30px 0;
    }
  }

  @media (max-width: 767.9px) {
    &.top {
      margin: 20px 0 0 0;
      padding: 25px 0px 0px 0px;
    }
  }
`;

const FadeStyle = styled.div`
  position: absolute;
  width: 2257px;
  height: 1650px;
  left: -1100px;
  top: 1140px;
  opacity: 0;

  background: radial-gradient(
    38.84% 50% at 50% 50%,
    rgba(161, 52, 234, 0.34) 0%,
    rgba(161, 52, 234, 0) 100%
  );

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    width: 1944px;
    height: 1420px;
    right: 0;
    top: 1000px;
  }

  @media screen and (max-width: 767.9px) {
    width: 1240px;
    height: 907px;
    left: -694px;
    top: 1976px;
  }
`;

const CustomImg = styled(Img)`
  position: absolute;
  width: 100%;
  height: 588px;
  top: 0;
  left: 0;

  @media (max-width: 834px) {
    height: 427px;
  }
`;

const OverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 589px;
  top: 0;
  background: radial-gradient(100.62% 100% at 50% 0%, rgba(15, 15, 15, 0.32) 0%, #303030 100%);

  @media (max-width: 834px) {
    height: 427px;
  }
`;

const Page = ({ data }) => {
  const prismicSubCase = data.prismicSubCase;
  const [onPagePosition, setOnPagePosition] = useState();
  const documentSubCase = prismicSubCase.data;
  const [bannerTranslateY, setBannerTranslateY] = useState(0);
  const isTablet = useMedia(`(min-width:768px) and (max-width:1366px)`);

  useEffect(() => {
    const scrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop || document.body.scrollTop;
      setOnPagePosition(scrollPx);
    };
    window.addEventListener('scroll', scrollProgress);
    scrollProgress();
    if (onPagePosition > 1400 && !isTablet) {
      const root = document.getElementById('circlePink');
      root.style.cssText = `transition: opacity 2s ease-in-out; opacity:1;`;
    }
    if (onPagePosition > 1550 && isTablet) {
      const root = document.getElementById('circlePink');
      root.style.cssText = `transition: opacity 2s ease-in-out; opacity:1;`;
    }
    return () => {
      window.removeEventListener('scroll', scrollProgress);
    };
  }, [isTablet, onPagePosition]);

  const subCaseContent = {
    title: documentSubCase.banner_title.raw,
    desc: documentSubCase.banner_desc.raw,
    img: documentSubCase.banner_image.fluid,
    middle_title: documentSubCase.middle_bar_title.raw,
    middle_desc: documentSubCase.middle_bar_desc.raw,
    middle_desc_mobile: documentSubCase.middle_bar_mobile_desc.raw,
    middle_second_desc: documentSubCase?.middle_bar_second_desc?.raw,
    middle_desc_second_mobile: documentSubCase?.middle_bar_mobile_second_desc?.raw,
    link: documentSubCase.link_website.url,
    text_box_title: documentSubCase.text_box_title.raw,
    text_box_desc: documentSubCase.text_box_desc.raw,
    text_box_mobile_desc: documentSubCase.text_box_mobile_desc.raw,
    text_box_tablet_desc: documentSubCase.text_box_tablet_desc.raw,
    content: documentSubCase.body.find((field) => field.slice_type === 'content').items,
    footage: !!documentSubCase.body.find((field) => field.slice_type === 'footage')
      ? documentSubCase.body.find((field) => field.slice_type === 'footage').primary
      : null,
  };

  useEffect(() => {
    const onScroll = () => {
      const percentage = (window.pageYOffset / window.document.body.clientHeight) * 100;
      setBannerTranslateY(percentage);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!prismicSubCase) return null;

  return (
    <div>
      <SEO title={RichText.asText(subCaseContent.title)} />

      <div
        css={`
          position: fixed;
          width: 100vw;
          z-index: -1;
          transform: translateY(${-bannerTranslateY}px);
        `}
      >
        <CustomImg fluid={subCaseContent.img} />
        <OverLay />
      </div>
      <div
        css={`
          position: fixed;
          width: 100vw;
          z-index: -2;
          background: #303030;
          height: 100vh;
        `}
      />
      <div
        css={`
          z-index: 1;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 23%,
            rgba(48, 48, 48, 0.458420868347339) 58%,
            rgba(48, 48, 48, 0.9794292717086834) 80%
          );
        `}
      >
        <Banner
          image={subCaseContent.img}
          title={subCaseContent.title[0].text}
          description={RichText.asText(subCaseContent.desc)}
          button={false}
          link={subCaseContent.link}
        />

        <ContentStyle>
          <TextBackground
            title={RichText.asText(subCaseContent.text_box_title)}
            description={RichText.asText(subCaseContent.text_box_desc)}
            description_mobile={RichText.asText(subCaseContent.text_box_mobile_desc)}
            description_tablet={RichText.asText(subCaseContent.text_box_tablet_desc)}
          />
          <FadeStyle id="circlePink" />
          <Wrapper>
            {subCaseContent.content.map((data, index) => (
              <>
                <Content
                  image={data.content_image.fluid?.src}
                  mirror={(index + 1) % 2 === 0 ? true : false}
                  key={`content-${index}`}
                  topic_text={RichText.render(data.content_title.raw)}
                  detail_text={RichText.render(data.content_detail.raw)}
                  detail_text_tablet={RichText.render(data.content_detail_tablet.raw)}
                  detail_text_mobile={RichText.render(data.cnectent_detail_mobile.raw)}
                />
                {index === 1 && (
                  <CenterBar>
                    <Space className="top">
                      <h2>{RichText.render(subCaseContent.middle_title)}</h2>
                    </Space>
                    <Space className="bot">
                      <DetailText className="pc">
                        {RichText.render(subCaseContent.middle_desc)}
                      </DetailText>
                      <DetailText className="mobile">
                        {RichText.render(subCaseContent.middle_desc_mobile)}
                      </DetailText>
                    </Space>
                  </CenterBar>
                )}
                {index === 2 && subCaseContent.content.length >= 4 && (
                  <CenterBar>
                    <Space className="top">
                      <h2>{RichText.render(subCaseContent.middle_title)}</h2>
                    </Space>
                    <Space className="bot">
                      <DetailText className="pc">
                        {RichText.render(subCaseContent.middle_second_desc)}
                      </DetailText>
                      <DetailText className="mobile">
                        {RichText.render(subCaseContent.middle_desc_second_mobile)}
                      </DetailText>
                    </Space>
                  </CenterBar>
                )}
              </>
            ))}
          </Wrapper>
          {subCaseContent.footage ? (
            <Footage
              title={RichText.render(subCaseContent.footage.footage_title.raw)}
              detail={RichText.render(subCaseContent.footage.footage_detail.raw)}
              detail_tablet={RichText.render(subCaseContent.footage.footage_detail_tablet.raw)}
              detail_mobile={RichText.render(subCaseContent.footage.footage_detail_mobile.raw)}
              profile={RichText.render(subCaseContent.footage.footage_profile.raw)}
              profile_detail={RichText.render(subCaseContent.footage.footage_profile_detail.raw)}
            />
          ) : null}
        </ContentStyle>
      </div>

      <Footer />
    </div>
  );
};

export default withTranslate(Page);

export const query = graphql`
  query($uid: String) {
    prismicSubCase(uid: { eq: $uid }) {
      type
      id
      uid
      data {
        text_box_desc {
          raw
        }
        text_box_title {
          raw
        }
        body {
          ... on PrismicSubCaseBodyContent {
            id
            items {
              content_title {
                raw
              }
              content_detail {
                raw
              }
              content_image {
                fluid {
                  src
                }
              }
              cnectent_detail_mobile {
                raw
              }
              content_detail_tablet {
                raw
              }
            }
            slice_type
          }
          ... on PrismicSubCaseBodyFootage {
            id
            primary {
              footage_title {
                raw
              }
              footage_detail {
                raw
              }
              footage_profile {
                raw
              }
              footage_profile_detail {
                raw
              }
              footage_image {
                fluid(maxWidth: 90, maxHeight: 90) {
                  src
                }
              }
              footage_detail_mobile {
                raw
              }
              footage_detail_tablet {
                raw
              }
            }
            slice_type
          }
        }
        banner_title {
          raw
        }
        banner_image {
          fluid(maxWidth: 2048) {
            src
          }
        }
        banner_desc {
          raw
        }
        link_website {
          url
        }
        middle_bar_desc {
          raw
        }
        middle_bar_title {
          raw
        }
        middle_bar_second_desc {
          raw
        }
        text_box_mobile_desc {
          raw
        }
        text_box_tablet_desc {
          raw
        }
        middle_bar_mobile_desc {
          raw
        }
        middle_bar_mobile_second_desc {
          raw
        }
      }
    }
    prismicHomepageBodyFooter {
      primary {
        contact_address {
          text
        }
        contact_email {
          text
        }
        contact_linkedin {
          url
        }
      }
    }
  }
`;
