import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

// Components
import Header from './header';

// Styles
import '../../styles/layout.css';
import theme from '../../styles/theme';
import { StickyContainer, Sticky } from 'react-sticky';

// Contexts
import { HandleLanguage as LanguageProvider } from '../../contexts/language.context';
import { AnimationProvider } from '../../contexts/animation.context';
import { TagProvider } from '../../contexts/tag.context';

// Cookie
import { gdprCookieNotice } from 'cookie-handler-gdpr/dist/esm';

const Layout = ({ children, activeDocMeta = '', location = '' }) => {
  useEffect(() => {
    const gdpr = gdprCookieNotice({
      locale: 'de-de',
      colorPrimary: '#A134EA',
      colorSecondary: '#ffffff',
      iconColor: '#ffffff',
    });
    gdpr.setLanguage(activeDocMeta?.lang?.split('-')[0]);
  }, [activeDocMeta]);

  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider lang={activeDocMeta.lang}>
        <AnimationProvider>
          <TagProvider>
            <StickyContainer>
              <Sticky bottomOffset={-90} disableCompensation disableHardwareAcceleration>
                {({ style }) => (
                  <div
                    style={{
                      ...style,
                      backgroundColor: 'transparent',
                      zIndex: '100',
                      width: '100%',
                      position: 'fixed',
                    }}
                  >
                    <Header activeDocMeta={activeDocMeta} location={location} />
                  </div>
                )}
              </Sticky>
              <main id="main-wrapper" style={{ overflow: 'hidden' }}>
                {children}
              </main>
            </StickyContainer>
          </TagProvider>
        </AnimationProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activeDocMeta: PropTypes.object,
};

export default Layout;
