import React, { useState } from 'react';
import styled from 'styled-components';
import Reel from 'react-reel';
import { Waypoint } from 'react-waypoint';
import { useMedia } from '../../hook/index.ts';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isSmallSize }) => (isSmallSize ? `none` : `2fr 1fr 1fr 1fr`)};
  color: white;
  grid-gap: ${({ isSmallSize }) => (isSmallSize ? `40px` : `80px`)};
  position: relative;
  padding: 0;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.device.tablet}) and (max-width: 1439.9px) {
    padding: 60px 100px;
  }
  @media (max-width: 767.9px) {
    padding: 0 24px 0 24px;
    margin: auto;
  }
`;
const Content = styled.div`
  position: relative;
`;

const SubTitleContent = styled.div`
  ${({ theme }) => theme.typography.titleBar()};
  position: relative;
  font-weight: 600;
  align-items: center;
  @media (max-width: 425px) {
    ${({ theme }) => theme.typography.titleContent()};
    font-weight: 600;
  }
`;
const FontCounter = styled.div`
  ${({ theme }) => theme.typography.largeFont()};
`;
const SubFontCounter = styled.div`
  ${({ theme }) => theme.typography.footerTextContent()};
  margin-top: 5px;
  @media (max-width: 425px) {
    ${({ theme }) => theme.typography.customerContact()};
    line-height: 18px;
  }
`;
const OptimizedReel = styled(Reel)`
  display: none;
`;
const OnCounter = styled.div`
  display: ${({ isSmallSize }) => (isSmallSize ? `grid` : `contents`)};
  grid-template-columns: ${({ isSmallSize }) => (isSmallSize ? `1fr 1fr 1fr` : `none`)};

  @media (max-width: 1439.9px) {
    padding-left: 20px;
    grid-gap: 70px;
  }
  @media (max-width: 450px) {
    padding-left: 8px;
    grid-gap: 40px;
  }
  @media (max-width: 425px) {
    grid-gap: 45px;
  }
  @media (max-width: 325px) {
    grid-gap: 20px;
  }
`;

const theme = {
  reel: {
    height: '1em',
    display: 'flex',
    alignItems: 'flex-end',
    overflowY: 'hidden',
    fontSize: '56px',
    fontWeight: '600',
    lineHeight: '65px',
    textAlign: 'center',
  },
  group: {
    transitionDelay: '1000ms',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translate(0, 0)',
    height: '1em',
  },
  number: {
    height: '1em',
  },
};

const CompromisedLabel = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  @media (max-width: 500px) {
    div[role='presentation'] {
      font-size: 52px !important;
    }
    justify-items: center;
  }
`;

const CompromisedLabelDevelop = styled(CompromisedLabel)`
  @media (max-width: 500px) {
    div[role='presentation'] {
      height: 52px !important;
    }
    justify-items: center;
  }
`;

const CompromisedLabelManager = styled(CompromisedLabel)`
  @media (max-width: 500px) {
    div[role='presentation'] {
      line-height: 62px !important;
    }
    justify-items: center;
  }
`;

const CompromiseImg = styled(Img)`
  width: 41px;
  height: 41px;
`;

const Counter = (counterContent) => {
  const [state, setState] = useState(false);

  const isSmallSize = useMedia(`(max-width: 1439.9px)`);

  const getReelTheme = () => ({
    ...theme,
    reel: {
      ...theme.reel,
    },
  });

  return (
    <Wrapper isSmallSize={isSmallSize}>
      <Content>
        <SubTitleContent>Unser Team wächst!</SubTitleContent>
      </Content>

      <OnCounter isSmallSize={isSmallSize}>
        <CompromisedLabelDevelop>
          <CompromiseImg fluid={counterContent.counterContent[0]?.icon_worker?.fluid} />

          <FontCounter>
            <Waypoint onEnter={() => setState(true)}>
              {state && (
                <OptimizedReel
                  theme={getReelTheme()}
                  text={counterContent.counterContent[0].amount_worker.raw[0].text}
                  duration={1500}
                  delay={300}
                />
              )}
            </Waypoint>
          </FontCounter>
          <SubFontCounter>
            {RichText.render(counterContent.counterContent[0].role_worker.raw)}
          </SubFontCounter>
        </CompromisedLabelDevelop>

        <CompromisedLabelManager css={``}>
          <CompromiseImg fluid={counterContent.counterContent[1]?.icon_worker?.fluid} />

          <FontCounter>
            <Waypoint onEnter={() => setState(true)}>
              {state && (
                <OptimizedReel
                  theme={getReelTheme()}
                  text={counterContent.counterContent[1].amount_worker.raw[0].text}
                  duration={2500}
                  delay={300}
                />
              )}
            </Waypoint>
          </FontCounter>
          <SubFontCounter>
            {RichText.render(counterContent.counterContent[1].role_worker.raw)}
          </SubFontCounter>
        </CompromisedLabelManager>

        <CompromisedLabelManager>
          <CompromiseImg fluid={counterContent.counterContent[2]?.icon_worker?.fluid} />

          <FontCounter>
            <Waypoint onEnter={() => setState(true)}>
              {state && (
                <OptimizedReel
                  theme={getReelTheme()}
                  text={counterContent.counterContent[2].amount_worker.raw[0].text}
                  duration={3500}
                  delay={300}
                />
              )}
            </Waypoint>
          </FontCounter>
          <SubFontCounter>
            {RichText.render(counterContent.counterContent[2].role_worker.raw)}
          </SubFontCounter>
        </CompromisedLabelManager>
      </OnCounter>
    </Wrapper>
  );
};

export default Counter;
