import styled from 'styled-components';
import { Link } from 'gatsby';

export const ButtonNoneBg = styled.a`
  ${({ theme }) => theme.typography.button()};
  color: ${({ theme }) => theme.color.textLink};
  background-color: ${({ theme }) => theme.color.textWhite};
  padding: 19px 90px;
  border-radius: 5px;
`;
export const ButtonText = styled(Link)`
  ${({ theme }) => theme.typography.link()};
  color: ${({ theme }) => theme.color.titleLightBlue};
  background-color: none;
  transition: color 0.1s;

  text-decoration: ${({ underline }) => (!!underline ? 'underline' : 'none')};

  &:hover {
    color: ${({ theme }) => theme.color.textLinkHover};
  }
`;

export const ButtonTextNoneLink = styled.button`
  ${({ theme }) => theme.typography.link()};
  color: ${({ theme }) => theme.color.textLink};
  background-color: transparent;
  transition: color 0.1s;
  border: none;
  outline: none;
  padding: 0;
  z-index: 0;

  &:hover {
    color: ${({ theme }) => theme.color.textLinkHover};
    cursor: pointer;
  }

  span {
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-out;
  }
`;

export const ButtonLink = styled.a`
  ${({ theme }) => theme.typography.link()};
  color: ${({ theme }) => theme.color.textLink};
  background-color: none;
  transition: color 0.1s;

  &:hover {
    color: ${({ theme }) => theme.color.textLinkHover};
  }
`;

export const NormalButton = styled.a`
  ${({ theme }) => theme.typography.button()}
  color: ${({ theme }) => theme.color.textWhite};
  background-color: ${({ theme }) => theme.color.textLink};
  padding: 15px 70px;
  border-radius: 50px;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    padding: 1em 1em;
  }
`;

export const OutlineButton = styled(Link)`
  ${({ theme }) => theme.typography.button()}
  color: ${({ theme }) => theme.color.textLink};
  background-color: ${({ theme }) => theme.color.textWhite};
  padding: 15px 80px;
  border-radius: 50px;
  border: 2px solid ${({ theme }) => theme.color.textLink};
  min-width: 300px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    padding: 15px 20px;
    margin-top: 10px;
    ${({ theme }) => theme.typography.footerContent()}
    font-weight: 700;
    min-width: 177px;
  }
`;
export const ButtonHighlight = styled.a`
  ${({ theme }) => theme.typography.button()};
  font-size: 16px;
  color: ${({ theme }) => theme.color.textWhite};
  text-transform: uppercase;

  padding: 19px 75px;
  border-radius: 5px;

  &.headerBar {
    width: 179px;
    padding: 20px 15px;
    text-align: center;

    background: radial-gradient(
        34.55% 166.96% at calc(var(--mouse-x, 1) * 575%) calc(var(--mouse-y, 1) * 2000%),
        rgba(55, 196, 231, 0.8) 0%,
        rgba(55, 196, 231, 0) 100%
      ),
      #a134ea;
  }

  &.longBar {
    padding: 15px 45px;
    max-width: 350px;

    background: radial-gradient(
        34.55% 166.96% at calc(var(--mouse-x, 1) * 575%) calc(var(--mouse-y, 1) * 2250%),
        rgba(55, 196, 231, 0.8) 0%,
        rgba(55, 196, 231, 0) 100%
      ),
      #a134ea;

    @media (max-width: 374px) {
      padding: 15px 16px;
    }
  }

  &.footerBar {
    display: flex;
    max-width: 350px;
    width: 100%;
    justify-content: center;
    line-height: 16px;
    padding: 19px 0px;

    background: radial-gradient(
        34.55% 166.96% at calc(var(--mouse-x, 1) * 575%) calc(var(--mouse-y, 1) * 2000%),
        rgba(55, 196, 231, 0.8) 0%,
        rgba(55, 196, 231, 0) 100%
      ),
      #a134ea;

    @media (max-width: 768px) {
      max-width: 415px;
      padding: 19px 0px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 15px 75px;
    &.smallButton {
      padding: 10px 20px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileS}) {
    &.smallButton {
      padding: 10px 20px;
    }
  }
`;
