import React from 'react';
import withTranslate from '../utils/withTransWrapper';
import SEO from '../components/seo';
import WhatWeDoPage from '../modules/whatwedo';

const WhatWeDoTemplate = () => {
  return (
    <>
      <SEO title="What we do" />
      <WhatWeDoPage />
    </>
  );
};

export default withTranslate(WhatWeDoTemplate, true);
