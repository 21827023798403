import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

// data
import { RichText } from 'prismic-reactjs';

// utils
import linkResolver from '../../utils/linkResolver';
import AnimateComp from '../../utils/animateWrapper';

const LayoutImage = styled.div`
  display: flex;
  justify-content: center;
`;

const BlogCardContainer = styled.a`
  white-space: pre;
  transition: all 150ms ease-in-out;
  text-decoration: none;
  color: currentColor;
  border-radius: 8px;
  @media (min-width: ${({ theme }) => theme.device.tablet}) and (max-width: 950px) {
    grid-template-columns: 4.5fr 7fr;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
  }
  &:hover {
    transition: all 150ms ease-in-out;
    .BlogCardAction {
      color: ${({ theme }) => theme.color.textLink};
      transition: all 150ms ease-in-out;
      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
      @media (min-width: ${({ theme }) => theme.device.laptop}) {
        position: absolute;
        bottom: 50px;
      }
    }
    .BlogCardContent::before {
      opacity: 0.02;
      transition: all 150ms ease-in-out;
    }
    .BlogCardImageContainer::before {
      opacity: 0.2;
      transition: all 150ms ease-in-out;
    }
  }

  @media (max-width: 667px) and (orientation: landscape) {
    max-width: 250px;
  }
`;

const BlogCardContent = styled.div`
  position: relative;
  border-radius: 8px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    grid-row: 2;
    border-radius: 0px;
  }
`;

const BlogCardCategory = styled.span`
  color: ${({ theme }) => theme.color.grey600};
  text-transform: uppercase;
  h5 {
    font-weight: 500;
  }
`;

const BlogCardTitle = styled.span`
  h1 {
    ${({ theme }) => theme.typography.titleContent()};
  }
`;
const TooltipText = styled.div`
  color: #fff;
  height: 76px;
  text-align: start;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;
  /* stylelint-disable */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;
const TooltipBox = styled.div`
  h1 {
    ${({ theme }) => theme.typography.link()};
    margin-bottom: 0px;
  }
  visibility: hidden;
  color: transparent;
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: auto;
  padding: 5px 5px;
  margin-top: 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, padding 0.5s ease-in-out;
  white-space: normal;
  &::before {
    content: '';
    position: absolute;
    visibility: hidden;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;
    left: 10%;
    top: -1px;
    z-index: 400;
    opacity: 0;
    transition: opacity 0.5s;
  }
`;
const TooltipCard = styled.div`
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: ${({ theme }) => theme.color.textContent};
    background-color: ${({ theme }) => theme.color.textWhite};
    width: 100%;
    padding: 8px 8px;
    border-radius: 4px;
    z-index: 3;
    &::before {
      content: '';
      visibility: visible;
      opacity: 1;
    }
  }
`;

const BlogCardBlurb = styled.div`
  ${({ theme }) => theme.typography.footerTextContent()};
  height: 50px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const ImageStyle = styled(Img)`
  max-width: 1200px !important;
  width: 100% !important;
  min-height: 285px !important;
  max-height: 285px !important;
  object-fit: cover !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04) !important;
  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    max-width: 300px !important;
    border-radius: 0px !important;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    object-fit: cover !important;
    min-height: 0 !important;
    min-height: 316px !important;
    min-width: 316px !important;
    max-height: 316px !important;
    max-width: 316px !important;
    border-radius: 8px !important;
  }
  @media (max-width: 667px) and (orientation: landscape) {
    max-height: 250px !important;
    min-height: 250px !important;
  }
`;
const DetailCard = styled.div`
  margin: 25px 25px 20px 0;
  color: white;
  @media (max-width: 767px) {
    margin: auto;
    margin-top: 24px;
    max-width: 285px;
    min-width: 285px;
  }
  @media (max-width: 425px) {
    min-width: 316px;
    max-width: 316px;
  }
  @media (max-width: 667px) and (orientation: landscape) {
    max-width: 250px;
    min-width: 250px;
  }
`;

const isTouchDevice = () => {
  if (typeof window === 'undefined') return null;
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

const BlogCard = ({ category = '', title, description, thumbnail, meta }) => (
  <AnimateComp animationType="fadeIn">
    {({ className }) => (
      <BlogCardContainer href={linkResolver(meta)} className={className}>
        <BlogCardContent className="BlogCardContent">
          <LayoutImage>
            <ImageStyle fluid={thumbnail.fluid} />
          </LayoutImage>
          <BlogCardCategory>{!!category ? RichText.render(category) : null}</BlogCardCategory>
          <DetailCard>
            <BlogCardTitle>
              <TooltipCard>
                <TooltipText>{RichText.render(title)}</TooltipText>
                {!isTouchDevice() && <TooltipBox>{RichText.render(title)}</TooltipBox>}
              </TooltipCard>
            </BlogCardTitle>
            <BlogCardBlurb>{RichText.asText(description)}</BlogCardBlurb>
          </DetailCard>
        </BlogCardContent>
      </BlogCardContainer>
    )}
  </AnimateComp>
);

export default BlogCard;

BlogCard.propTypes = {
  category: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  thumbnail: PropTypes.object.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
};
