export const color = {
  textLink: '#A134EA',
  textLinkHover: '#ff5b00',
  textContent: '#292D3D',
  textMenu: '#8E8E93',
  textSubMenu: '#3C4050',
  textWhite: '#FFFFFF',
  textFooter: '#909299',
  textGray: '#E1E5ED',
  filterColor: '#C7C7CC',
  textBlack: '#202124',
  cardLightBlue: '#43AFDC',
  titleLightBlue: '#70D2FC',
  cardPink: '#C51880',
  black: '#00000',
  blue500: '#5393fe',
  grey600: '#A9AAAB',
  grey200: '#F2F2F2',
  greyTag: '#E6E7EC',
  backgroundGrayHome: '#5A5A5A',
  grayBlack: '#303030',
};
