import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999;
  background: #fff;
  width: 100vw;
  opacity: 0.8;
`;
const Loading = () => {
  return <Wrapper className="lds-dual-ring">Loding Preview...</Wrapper>;
};

export default Loading;
