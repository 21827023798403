import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import BannerTeam from '../components/team';
import SEO from '../components/seo';

// data
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// utils
import withTranslate from '../utils/withTransWrapper';
import Footer from '../components/layout/footer';
import { useMedia } from '../hook/index.ts';

// Icon
import { supportsWebp } from '../utils/supportWebp';

//number counter
import Counter from '../components/counter';

const Wrapper = styled.div`
  max-width: 1020px;
  text-align-last: start;
  width: 100%;
  justify-content: start;
  margin: auto;

  p {
    position: relative;
  }
`;

const Background = styled.div`
  height: auto;
  max-width: 100vw;
  width: 100%;
  position: relative;
  padding: 0 24px;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    padding: 0 100px;
  }

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    height: 100%;
  }

  .content_title {
    ${({ theme }) => theme.typography.footerSubHeadline()};
    margin: 0;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: #ffffff;
    width: 100%;
    text-align: start;
    white-space: pre-line;
    position: relative;

    @media (max-width: ${({ theme }) => theme.device.tablet}) {
      ${({ theme }) => theme.typography.contentTitle()};
      margin: 0;
      text-align: start;
    }

    @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
      text-align: start;
      h1 {
        ${({ theme }) => theme.typography.titleContent()};
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.device.mobileS}) {
      text-align: start;
      h1 {
        ${({ theme }) => theme.typography.titleContent()};
        font-size: 20px;
      }
    }
  }

  .banner_description {
    ${({ theme }) => theme.typography.link()};
    line-height: 27px;
    letter-spacing: 0;
    color: #ffffff;
    width: 100%;
    position: relative;
    margin-bottom: 0;

    @media (min-width: 426px) and (max-width: 629.9px) {
      p {
        ${({ theme }) => theme.typography.content3d()};
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
      }
    }

    @media (min-width: 630px) and (max-width: 700px) {
      p {
        ${({ theme }) => theme.typography.content3d()};
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
      p {
        ${({ theme }) => theme.typography.link()};
        line-height: 27px;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.device.mobileM}) {
      p {
        ${({ theme }) => theme.typography.formHeadline()};
        font-size: 16px;
        line-height: 20px;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.device.mobileS}) {
      p {
        ${({ theme }) => theme.typography.formHeadline()};
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

const FadeStyle = styled.div`
  position: absolute;
  width: 2257px;
  height: 1650px;
  left: -1072px;
  bottom: 360px;
  opacity: 0;

  background: radial-gradient(
    38.84% 50% at 50% 50%,
    rgba(161, 52, 234, 0.34) 0%,
    rgba(161, 52, 234, 0) 100%
  );

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 1000px;
    height: 800px;
    left: -442px;
    top: -459px;
  }
`;

const WrapperMain = styled.div`
  background-color: #313131;
  position: relative;
`;

const Vision = ({ data }) => {
  const documentTeam = data.prismicTeam.data;
  const [onPagePosition, setOnPagePosition] = useState();
  const [sizePage] = useState(70);
  const [bannerTranslateY, setBannerTranslateY] = useState(0);

  const isMobileSize = useMedia(`(max-width:425px)`);

  useEffect(() => {
    const scrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop || document.body.scrollTop;
      setOnPagePosition(scrollPx);
    };
    window.addEventListener('scroll', scrollProgress);
    scrollProgress();
    if (onPagePosition === 0 && isMobileSize) {
      const root = document.getElementById('circlePink');
      root.style.cssText = `opacity:0;`;
    }
    if (onPagePosition < 10 && !isMobileSize) {
      const root = document.getElementById('circlePink');
      root.style.cssText = `opacity:0;`;
    }
    if (onPagePosition > 10 && isMobileSize) {
      const root = document.getElementById('circlePink');
      root.style.cssText = `transition: opacity 2s ease-in-out; opacity:1;`;
    }
    if (onPagePosition > sizePage) {
      const root = document.getElementById('circlePink');
      root.style.cssText = `transition: opacity 2s ease-in-out; opacity:1;`;
    }
    return () => {
      window.removeEventListener('scroll', scrollProgress);
    };
  }, [isMobileSize, onPagePosition, sizePage]);

  const counterContent = documentTeam.body[0]?.items;

  useEffect(() => {
    const onScroll = () => {
      const percentage = (window.pageYOffset / window.document.body.clientHeight) * 100;
      setBannerTranslateY(percentage);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const [isSupportWebP, setIsSupportWebP] = useState(false);

  useEffect(() => {
    supportsWebp().then((res) => setIsSupportWebP(res));
  }, []);

  return (
    <WrapperMain isSupportWebP={isSupportWebP}>
      <SEO title="Team" />
      <BannerTeam
        title={RichText.render(documentTeam.team_banner_title.raw)}
        desc={RichText.render(documentTeam.team_banner_desc.raw)}
        desc_tablet={RichText.render(documentTeam.team_banner_desc_tablet.raw)}
        desc_mobile={RichText.render(documentTeam.team_banner_desc_mobile.raw)}
        image={documentTeam.team_banner_image.fluid}
        translateY={-bannerTranslateY}
      />
      <Background>
        <Wrapper>
          <div className={`content_title`}>{RichText.render(documentTeam.team_sub_title.raw)}</div>

          <div>
            <p className={`banner_description`}>
              {RichText.render(documentTeam.team_sub_desc.raw)}
            </p>
          </div>
        </Wrapper>
      </Background>
      <div
        css={`
          position: relative;
          width: 100%;
          max-width: 1020px;
          margin: auto;
          margin-top: 140px;
          margin-bottom: 140px;
        `}
      >
        <Counter counterContent={counterContent} />
      </div>
      <FadeStyle id="circlePink" />
      {/* <SectionCEO
        css={`
          background: rgb(255, 255, 255);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(48, 48, 48, 1) 87%
          );
        `}
      >
        <div
          css={`
            max-width: 1100px;
            margin: auto;
          `}
        >
          {ceoContent.map((data, index) => {
            return <Player data={data} index={index} key={data.name_title} />
          })}
        </div>
      </SectionCEO> */}

      <Footer />
    </WrapperMain>
  );
};

export default withTranslate(Vision);

export const query = graphql`
  query {
    prismicTeam {
      type
      id
      data {
        team_banner_desc {
          raw
        }
        team_banner_image {
          fluid(maxWidth: 2048) {
            src
          }
        }
        team_banner_title {
          raw
        }
        team_sub_decs_tablet {
          raw
        }
        team_sub_desc {
          raw
        }
        team_sub_desc_mobile {
          raw
        }
        team_sub_title {
          raw
        }
        body {
          ... on PrismicTeamBodyTeamConntent {
            id
            items {
              company {
                raw
              }
              name_title {
                raw
              }
              position {
                raw
              }
              video_content {
                embed_url
                url
              }
              video_desc_content {
                raw
              }
              video_desc_content_mobile {
                raw
              }
              video_desc_content_tablet {
                raw
              }
            }
            slice_type
          }
          ... on PrismicTeamBodyCounterWorker {
            id
            items {
              amount_worker {
                raw
              }
              icon_worker {
                fluid(maxWidth: 41) {
                  src
                }
              }
              role_worker {
                raw
              }
            }
          }
        }
        team_banner_desc_mobile {
          raw
        }
        team_banner_desc_tablet {
          raw
        }
      }
    }
  }
`;
