import React from 'react';
import BlogPostTemplate from '../templates/BlogPost';

export const UnpublishedBlog = (props) => {
  // const previewData = window.__PRISMIC_PREVIEW_DATA__
  // => Perform any logic from previewData to determine the correct page or template component to use.

  return <BlogPostTemplate {...props} />;
};

export default UnpublishedBlog;
