import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import Menu from './menu';

// data
import { Link } from 'gatsby';

import { ButtonHighlight } from '../button';
import { useMedia } from '../../hook/index.ts';
import Logo20IT from '../../images/logo.svg';

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 90px;
  margin: 0 auto;
  padding: 0 114px;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 100;
  align-self: center;

  @media (max-width: 1112px) {
    padding: 0 56px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 24px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  background-color: rgba(0, 0, 0, ${(props) => props.opacity});
  color: black;

  backdrop-filter: ${({ opacity }) => (opacity === 0 ? 'none' : 'blur(5px)')};
  transition: all 0.3s;
`;

const Toggle = styled.div`
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 20px;
  z-index: 10;
`;

const Hamburger = styled.div`
  background-color: ${({ theme, opacity, appearable }) =>
    opacity === 0 && !appearable ? theme.color.textWhite : theme.color.textMenu};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${({ theme, opacity, appearable }) =>
      opacity === 0 && !appearable ? theme.color.textWhite : theme.color.textMenu};
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) => (props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)')};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const NavBarbox = styled.div`
  ${({ theme }) => theme.typography.formHeadline()};
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 592px;
    justify-content: flex-start;
    text-align: center;
    transition: all 0.3s ease-in;
    padding-top: 100px;
    top: 0;
    left: ${(props) => (props.open ? '-150%' : '0')};
    background-color: ${({ theme }) => theme.color.grayBlack};
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    height: 100vh;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

const Header = ({ activeDocMeta, location }) => {
  const [opacity, setOpacity] = useState(0);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const expandAble =
    activeDocMeta.type === 'privacy-markdown' ||
    activeDocMeta.type === 'imprint-page' ||
    activeDocMeta.type === 'blog-page' ||
    activeDocMeta.type === 'blog-post';

  const isSmallSize = useMedia(`(max-width: 1024px)`);

  useEffect(() => {
    if (window.pageYOffset) {
      setOpacity(0.9);
    }

    const headerOnScroll = () => {
      let op = window.pageYOffset;
      if (window.scrollY) {
        op = 100;
        setOpacity(op * 0.009);
      } else {
        setOpacity(0);
      }
    };

    window.addEventListener('scroll', headerOnScroll);

    return () => {
      window.removeEventListener('scroll', headerOnScroll);
    };
  }, [opacity]);

  useEffect(() => {
    const root = document.getElementById('isHeaderButton');

    // eslint-disable-next-line no-unused-expressions
    root?.addEventListener('mousemove', (evt) => {
      let x = evt.offsetX / window.innerWidth;
      let y = evt.offsetY / window.innerHeight;
      root.style.setProperty('--mouse-x', x);
      root.style.setProperty('--mouse-y', y);
    });

    return root?.addEventListener('mouseout', () => {
      root.style.setProperty('--mouse-x', -50);
      root.style.setProperty('--mouse-y', -50);
    });
  }, []);

  const { pathname } = location;

  // TODO: check path name to including tags.
  const tagsPath = !!pathname ? pathname.split('/')[2] : '';

  // TODO: active menu blogs
  const blogPath =
    !!tagsPath && pathname.split('/')[1].includes('blog') ? pathname.split('/')[1] : null;

  // TODO: active menu case
  const casePath =
    !!tagsPath && pathname.split('/')[1].includes('case') ? pathname.split('/')[1] : null;

  useEffect(() => {
    window.addEventListener('resize', setNavbarOpen(false));

    return () => {
      window.removeEventListener('resize', setNavbarOpen(false));
    };
  }, []);

  return (
    <Navigation opacity={opacity}>
      {isSmallSize ? (
        <>
          <LogoWrap>
            <Link
              to="/"
              css={`
                z-index: 20;
              `}
            >
              <img
                id="logo"
                alt="small-logo"
                src={Logo20IT}
                css={`
                  height: 64px !important;
                  width: 118px !important;
                  z-index: 10 !important;
                  max-width: unset !important;
                `}
              />
            </Link>
          </LogoWrap>
          <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
            {navbarOpen ? (
              <Hamburger open opacity={opacity} appearable={expandAble} />
            ) : (
              <Hamburger opacity={opacity} appearable={expandAble} />
            )}
          </Toggle>

          <NavBarbox opacity={opacity} open={!navbarOpen}>
            <div
              id="NavBarbox-wrapper"
              css={`
                background-color: #303030;
                width: 100%;
                height: 100%;
                overflow: auto;
                padding-bottom: 32px;
              `}
            >
              <Menu opacity={opacity} appearable={expandAble} isBlogs={!!blogPath} />
              <div
                css={`
                  height: 100px;
                  width: 100%;
                  @media screen and (max-width: 767px) and (orientation: landscape) {
                    height: 100vh;
                  }
                `}
              >
                <ButtonHighlight
                  href="mailto:mail@20scoops.com"
                  className="headerBar"
                  target="_blank"
                  rel="noopener noreferrer"
                  css={`
                    && {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 330px;
                      height: 56px;
                      margin: auto;
                    }
                  `}
                  id="isHeaderButton"
                >
                  Termin vereinbaren
                </ButtonHighlight>
              </div>
            </div>
          </NavBarbox>
        </>
      ) : (
        <>
          <LogoWrap>
            <Link
              to="/"
              css={`
                z-index: 20;
              `}
            >
              <img
                id="logo"
                alt="regular-logo"
                src={Logo20IT}
                css={`
                  height: 64px !important;
                  width: 118px !important;
                  z-index: 10 !important;
                  max-width: unset !important;
                `}
              />
            </Link>
            <NavBarbox
              opacity={opacity}
              open={!navbarOpen}
              css={`
                margin-left: 57px;
              `}
            >
              <Menu
                opacity={opacity}
                appearable={expandAble}
                isBlogs={!!blogPath}
                isCase={!!casePath}
              />
            </NavBarbox>
          </LogoWrap>
          <NavBarbox opacity={opacity} open={!navbarOpen}>
            <ButtonHighlight
              href="mailto:mail@20scoops.com"
              className="headerBar"
              target="_blank"
              rel="noopener noreferrer"
              id="isHeaderButton"
              css={`
                && {
                  width: 269.14px;
                }
              `}
            >
              Termin vereinbaren
            </ButtonHighlight>
          </NavBarbox>
        </>
      )}
    </Navigation>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
