const en = {
  translation: {
    case_studies_menu: 'case studies',
    wiki_menu: 'wiki',
    vision_menu: 'vision',
    blog_menu: 'blog',
    privacy_menu: 'privacy',
    imprint_menu: 'imprint',
    home_path: '/en-us',
    case_studies_path: '/case-studies/en-us',
    wiki_path: '/wiki/en-us',
    vision_path: '/vision/en-us',
    blog_path: '/blog',
    show_case: 'show cases',
    show_vision: "20IT's vision",
    show_wiki: "20IT's wiki",
    procom_invest_path: '/case-studies/procom-invest/en-us',
    procuratio_path: '/case-studies/procuratio/en-us',
    whitechart_path: '/case-studies/whitechart/en-us',
    privacy_path: '/privacy/en-us',
    imprint_path: '/imprint/en-us',
    get_call: 'get a call back',
    landing_footage_title: 'Got a project, an idea, or feedback?',
    landing_footage_address_title: 'We are here:',
    landing_footage_email_title: 'Shoot us a line:',
    show_project: 'show case',
    show_privious: 'show previous case',
    show_next: 'show next case',
    footer_detail: 'a company of 20Scoops',
    thankyou: 'Thanks for trusting in 20IT.',
    next: 'next',
    previous: 'previous',
    author: 'Author',
    homepage_title: '20IT Your personal assistant could be digital.',
    coming_soon: 'Coming soon...',
  },
};

export default en;
