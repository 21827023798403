import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import de from '../locales/de';
import en from '../locales/en';

const LanguageContext = createContext();

const LanguageProvider = LanguageContext.Provider;

const useHandleLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useHandleLanguage must be used within a LanguageProvider');
  }
  return context;
};

const LanguagePropyTypes = {
  children: PropTypes.node,
};

const HandleLanguage = ({ children, lang }) => {
  return (
    <LanguageProvider
      value={{
        t: (key) => {
          const languages = lang === 'de-de' ? de.translation : en.translation;
          return languages[key];
        },
      }}
    >
      {children}
    </LanguageProvider>
  );
};

HandleLanguage.propTypes = LanguagePropyTypes;

export { HandleLanguage, useHandleLanguage };
