import React from 'react';

// Components
import HomePageComponent from '../modules/homepage';

// data
import { graphql } from 'gatsby';

// utils
import withTranslate from '../utils/withTransWrapper';
import SEO from '../components/seo';

const Homepage = ({ data }) => {
  return (
    <>
      <SEO title="Let's get digital!" />
      <HomePageComponent data={data} />
    </>
  );
};

export default withTranslate(Homepage);

export const query = graphql`
  query {
    prismicHomepage {
      data {
        body {
          ... on PrismicHomepageBodyContent {
            id
            items {
              content_title {
                raw
              }
              content_image {
                fluid {
                  src
                }
              }
              content_desc {
                raw
              }
              content_desc_mobile {
                raw
              }
              content_desc_tablet {
                raw
              }
            }
            slice_type
          }
          ... on PrismicHomepageBody3d {
            id
            items {
              content_3d_title {
                raw
              }
              content_3d_desc {
                raw
              }
              content_3d_image {
                fluid {
                  src
                }
              }
              content_3d_image_webp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
              content_3d_desc_mobile {
                raw
              }
              content_3d_desc_tablet {
                raw
              }
            }
            slice_type
          }
          ... on PrismicHomepageBodyFooter {
            id
            primary {
              contact_address {
                raw
              }
              contact_email {
                raw
              }
              contact_linkedin {
                url
              }
            }
          }
        }
        banner_desc {
          raw
        }
        banner_title {
          raw
        }
        banner_desc_mobile {
          raw
        }
      }
    }
  }
`;
