import React from 'react';
import styled from 'styled-components';

const BannerWrapper = styled.div`
  width: 100%;
  height: 588px;
  position: relative;

  display: flex;
  align-items: center;
  text-align: center;

  @media (max-width: 425px) {
    height: 427px;
    top: 60px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.textWhite};
  position: absolute;
  width: 100%;
  margin: 0px auto;
  flex-flow: row wrap;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => theme.typography.bigTitleBanner()};
  width: 100%;
  font-size: 72px;

  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    ${({ theme }) => theme.typography.footageTitle()};
    font-size: 72px;
    font-weight: 700;
    line-height: 108px;
  }

  @media (max-width: 425px) {
    font-size: 44px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileS}) {
    ${({ theme }) => theme.typography.titleBar()};
  }
`;

export const TitleDescription = styled.div`
  ${({ theme }) => theme.typography.footageDetail()};
  white-space: pre-line;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    margin: 0;
    padding: 0 25px;
  }

  @media (max-width: 767.9px) {
    ${({ theme }) => theme.typography.formHeadline()};
    white-space: inherit;
  }

  @media (max-width: 426px) {
    max-width: 317px;
    margin: auto;
    padding-top: 20px;
  }
`;
export const ButtonWrapper = styled.div`
  ${({ theme }) => theme.typography.description()};
  width: 100%;
  text-transform: uppercase;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.typography.buttonBig()};
  color: #ffffff;
  background-color: transparent;
  margin: 38px 0 0 0;
  text-transform: uppercase;
  width: 296px;
  height: 56px;
  padding: 0.25em 1em;
  border: 1px solid #ffffff;
  letter-spacing: 0.05em;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;

  background-image: linear-gradient(to top, #70d2fc 50%, transparent 50%),
    linear-gradient(to top, #70d2fc 50%, transparent 50%);

  background-clip: text, padding-box;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.2s ease-in-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-position: bottom;
    }
  }
`;

const LinkWebsite = styled.a`
  color: ${({ theme }) => theme.color.textWhite};
  padding: 0px;
  text-transform: uppercase;
`;

const TIXL_PROJECT = 'tixl';
const PROCURATIO_PROJECT = 'procuratio';

const Banner = (props) => {
  console.log(props);
  return (
    <BannerWrapper>
      <TextWrapper>
        <TitleWrapper>{props.title}</TitleWrapper>
        <TitleDescription>{props.description}</TitleDescription>
        <ButtonWrapper>
          <LinkWebsite href={props.link} target="_blank" rel="noopener noreferrer">
            {![PROCURATIO_PROJECT, TIXL_PROJECT].includes(props?.title?.toLowerCase()) ? (
              <ButtonStyle>Seite besuchen</ButtonStyle>
            ) : null}
          </LinkWebsite>
        </ButtonWrapper>
      </TextWrapper>
    </BannerWrapper>
  );
};

export default Banner;
