import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { TagsFunc } from '../../contexts/tag.context';

const LinkMenu = styled(Link)`
  ${({ theme }) => theme.typography.link()};
  display: inline-block;
  white-space: nowrap;
  /* margin: 0 1vw; */
  transition: all 200ms ease-in;
  position: relative;
  width: 100%;
  opacity: 0.6;

  color: ${({ theme }) => theme.color.textGray};

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: ${({ theme }) => theme.color.textLink};
    ::after {
      width: 100%;
    }
  }

  &.activeStyles {
    color: ${({ theme }) => theme.color.textWhite};
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    ${({ theme }) => theme.typography.link()};
    padding: 20px 0px 20px 0;
    z-index: 6;
    filter: none;
    margin: 30px 0px;
    text-align-last: center;
    span {
      margin: 0 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 20px 0px;
  }

  @media (min-width: 500px) and (max-width: 900px) and (orientation: landscape) {
    margin: 0;
  }
`;

const Menu = ({ opacity, appearable, isBlogs = '', isCase = '' }) => {
  const { setTagsState } = useContext(TagsFunc);

  return (
    <>
      <LinkMenu
        to="/what-we-do"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
        className={isCase ? 'activeStyles' : null}
      >
        <span>What we do</span>
      </LinkMenu>
      <LinkMenu
        to="/case-studies"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
        className={isCase ? 'activeStyles' : null}
        css={`
          margin-left: 28px;
          margin-right: 0px;
          @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 0px;
          }
          @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            margin-left: 0px;
          }
          @media (min-width: 481px) and (max-width: 767px) {
            margin-left: 0px;
          }
          @media (min-width: 280px) and (max-width: 480px) {
            margin-left: 0px;
          }
        `}
      >
        <span>Erfolgsgeschichten</span>
      </LinkMenu>
      <LinkMenu
        to="/team"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
        css={`
          margin-left: 28px;
          margin-right: 28px;
          @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 0px;
            margin-right: 0px;
          }
          @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            margin-left: 0px;
            margin-right: 0px;
          }
          @media (min-width: 481px) and (max-width: 767px) {
            margin-left: 0px;
            margin-right: 0px;
          }
          @media (min-width: 280px) and (max-width: 480px) {
            margin-left: 0px;
            margin-right: 0px;
          }
        `}
      >
        <span>Team</span>
      </LinkMenu>
      <div
        onClick={() => {
          setTagsState('main');
        }}
      >
        <LinkMenu
          to="/blog?tags=main"
          opacity={opacity}
          appearable={appearable.toString()}
          activeClassName="activeStyles"
          partiallyActive={true}
          className={isBlogs ? 'activeStyles' : null}
        >
          <span>Blog</span>
        </LinkMenu>
      </div>
    </>
  );
};

export default Menu;
