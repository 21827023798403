import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

// Components
import SEO from '../components/seo';
import Content from '../components/case/content';

// data
import { graphql } from 'gatsby';

// utils
import withTranslate from '../utils/withTransWrapper';

// Picture
import Footer from '../components/layout/footer';
import { useMedia } from '../hook/index.ts';

export const BannerWrapperCase = styled.div`
  cursor: pointer;
  opacity: ${({ hoverIndex, index }) => (hoverIndex === index ? 1 : 0.5)};
  transition: all 300ms;
  &:hover {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    opacity: 1;
  }
`;
export const BackgroundColorAndHover = styled.div`
  min-height: 100vh;
  display: flex;
  background: ${({ theme, image }) =>
    image
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67)),url(${image})`
      : theme.color.grayBlack};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    background: ${({ theme }) => theme.color.grayBlack};
  }
`;

const ContentStyle = styled.div`
  padding: 20% 22%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;

  .picture {
    display: none;
  }

  @media (min-width: 1281px) {
    max-width: 710px;
    padding: 0px;
    margin: auto;
    height: 150px;
    align-content: center;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    max-width: 710px;
    padding: 0px;
    margin: auto;
    height: 300px;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    display: block;
    padding: 15% 5% 1px 5%;

    .picture {
      display: block;
    }
  }

  @media screen and (max-width: 834px) {
    display: block;
    padding: 120px 0 1px 0;
  }
`;

const CaseStudies = ({ data }) => {
  const isLessThan1024 = useMedia(`(max-width: 1024px)`, false);
  const [hoverIndex, setHoverIndex] = useState(0);
  const prismicCaseStudies = data.prismicCaseStudies;
  const document = prismicCaseStudies.data;

  const footer = useMemo(() => {
    return <Footer />;
  }, []);

  const background = useMemo(() => {
    return (
      <>
        {document.case_field.map((data, index) => {
          return (
            <>
              <img
                alt={data.background_case_image.fluid.src}
                src={data.background_case_image.fluid.src}
                css={`
                  position: absolute;
                  min-width: 100vw;
                  height: 100vh;
                  transition: all 800ms;
                  opacity: ${isLessThan1024 ? 0 : hoverIndex === index ? 1 : 0};
                  background-repeat: no-repeat;
                  background-size: cover;
                `}
              />
            </>
          );
        })}
        <div
          css={`
            position: absolute;
            min-width: 100vw;
            height: 100vh;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67));
          `}
        />
      </>
    );
  }, [hoverIndex, document.case_field, isLessThan1024]);

  if (!prismicCaseStudies) return null;

  return (
    <>
      <BackgroundColorAndHover id="BackgroundColorAndHover">
        <div
          css={`
            position: absolute;
          `}
        >
          {isLessThan1024 ? null : background}
        </div>

        <ContentStyle>
          <SEO title="Erfolgsgeschichten" />
          {document.case_field.map((data, index) => {
            return (
              <>
                <Content
                  title={data.case_title.raw[0].text}
                  description={data.case_desc.raw[0].text}
                  background={data.background_case_image.fluid.src}
                  index={index}
                  linkTo={data.case_link.raw[0].text}
                  setHoverIndex={setHoverIndex}
                  hoverIndex={hoverIndex}
                />
              </>
            );
          })}
        </ContentStyle>
      </BackgroundColorAndHover>
      {footer}
    </>
  );
};

export default withTranslate(CaseStudies);

export const query = graphql`
  query {
    prismicCaseStudies {
      type
      id
      data {
        case_field {
          background_case_image {
            fluid(maxWidth: 2048) {
              src
            }
          }
          case_desc {
            raw
          }
          case_link {
            raw
          }
          case_title {
            raw
          }
        }
      }
    }
  }
`;
