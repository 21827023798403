import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useLocation } from 'react-use';

const TagsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-top: 28px;
  margin: 0 auto;
`;

const Tags = styled.div`
  ${({ theme }) => theme.typography.formHeadline};
  font-size: 12px;
  color: ${({ theme }) => theme.color.textWhite};
  background: rgba(255, 255, 255, 0.18);
  border-radius: 100px;
  padding: 12px 16px;
  cursor: pointer;

  margin: 0 12px 10px 0px;

  @media screen and (min-width: 1367px) {
    font-size: 16px;
    padding: 10px 16px;
  }

  @media (hover: hover) {
    :hover {
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.06);
      background: rgba(255, 255, 255, 0.6);
    }
  }
`;

const TagsLink = styled(Link)`
  ${({ theme }) => theme.typography.formHeadline};
  font-size: 12px;
  color: ${({ theme }) => theme.color.textWhite};
  background: rgba(255, 255, 255, 0.18);
  border-radius: 100px;
  padding: 12px 16px;
  cursor: pointer;

  margin: 0 12px 10px 0px;

  @media screen and (min-width: ${({ theme }) => theme.device.tablet}) {
    font-size: 16px;
    padding: 10px 16px;
  }

  :hover {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 0.6);
  }
`;

const fadeOutWrapper = () => {
  const root = document.getElementById('rowDetailWrapper');
  if (root) {
    root.style.cssText = `
  transition: opacity 0.3s ease-in;
  opacity : 0; 
  `;
  }
};

const fadeInWrapper = () => {
  const root = document.getElementById('rowDetailWrapper');
  if (root) {
    root.style.cssText = `
  transition: opacity 0.3s ease-in;
  opacity : 1; 
  `;
  }
};

const TagComponent = ({
  blogPost = [],
  currentTag = '',
  url = false,
  handleChangeTag,
  tagsState,
  setTagsState,
}) => {
  let tags = [];

  const { href } = useLocation();

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('tags')) {
      fadeOutWrapper();
      setTimeout(() => {
        fadeInWrapper();
      }, 250);
      setTimeout(() => {
        return setTagsState && setTagsState(url.searchParams.get('tags'));
      }, 200);
    }
  }, [href, setTagsState]);

  // receive from blog post.
  if (!!blogPost.tags) {
    blogPost.tags.forEach((tag) => {
      tags.push(tag);
    });
  }

  // receive from blog list.
  if (blogPost.length > 0) {
    blogPost.map((item) => {
      return item.node.tags.forEach((tag) => {
        tags.push(tag);
      });
    });
  }

  // remove duplicate tags
  const tag = tags.filter((a, b) => tags.indexOf(a) === b && a !== currentTag.toLowerCase());

  return (
    <>
      {tags.length > 0 ? (
        <TagsWrapper>
          {tag.map((t, i) =>
            url === true ? (
              <TagsLink key={`${t}-${i}`} to={`/blog/?tags=${t}`}>
                {t}
              </TagsLink>
            ) : (
              <Tags
                key={`${t}-${i}`}
                onClick={() => {
                  fadeOutWrapper();
                  setTimeout(() => {
                    fadeInWrapper();
                    if (tagsState === t) {
                      handleChangeTag('main');
                    } else {
                      handleChangeTag(t);
                    }
                  }, 250);
                }}
                css={`
                  ${tagsState === t
                    ? `background-color: #70d2fc; :hover{background-color:#70d2fc}`
                    : null}
                `}
              >
                {t}
              </Tags>
            ),
          )}
        </TagsWrapper>
      ) : null}
    </>
  );
};

TagComponent.propTypes = {
  blogPost: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  blogPage: PropTypes.object.isRequired,
};

export default TagComponent;
