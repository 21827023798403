import React from 'react';
import styled from 'styled-components';
// Components
import PrivacyWrapper from '../components/privacy';
import Footer from '../components/layout/footer';

// data
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// utils
import withTranslate from '../utils/withTransWrapper';
import SEO from '../components/seo';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.grayBlack};
  color: ${({ theme }) => theme.color.textWhite};
`;

const Privacy = ({ data }) => {
  const prismicPrivacy = data.prismicPrivacyMarkdown;
  if (!prismicPrivacy) return null;
  const document = prismicPrivacy.data;

  return (
    <>
      <SEO title="Datenschutz" />
      <Wrapper>
        <PrivacyWrapper markdown={RichText.asText(document.data_privacy.raw)} />
        <Footer contactShow={false} />
      </Wrapper>
    </>
  );
};

export default withTranslate(Privacy);

export const query = graphql`
  query {
    prismicPrivacyMarkdown {
      type
      id
      data {
        data_privacy {
          raw
        }
      }
    }
  }
`;
