import React from 'react';

// components
import {
  ContentWrapper,
  ImageWrapper,
  DescWrapper,
  TopicsWrapper,
  DetailWrapper,
  TextBoxWrapper,
} from './index.view';

// Utils
import AnimateComp from '../../../utils/animateWrapper';

const Content = (props) => {
  return (
    <ContentWrapper mirror={props.mirror}>
      <AnimateComp animationType="fadeIn">
        {({ className }) => (
          <ImageWrapper className={className} mirror={props.mirror}>
            <img
              src={props.image}
              alt="content landing"
              css={`
                height: ${props.mirror ? `477px` : `350px`};
                width: ${props.mirror ? `772px` : `570px`};
                object-fit: cover;

                @media (max-width: 1024px) {
                  height: ${props.mirror ? `330px` : `280px`};
                }

                @media (max-width: 767px) {
                  width: 772px;
                }

                @media (max-width: 425px) {
                  height: 230px;
                }
              `}
            />
          </ImageWrapper>
        )}
      </AnimateComp>
      <AnimateComp animationType="fadeIn">
        {({ className }) => (
          <DescWrapper className={className} mirror={props.mirror}>
            {!!props.children ? (
              props.children
            ) : (
              <>
                <TextBoxWrapper color={props.color}>
                  <TopicsWrapper>{props.topic_text}</TopicsWrapper>
                  <DetailWrapper>{props.detail_text}</DetailWrapper>
                </TextBoxWrapper>
              </>
            )}
          </DescWrapper>
        )}
      </AnimateComp>
    </ContentWrapper>
  );
};

export default Content;
