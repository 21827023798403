import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { useMedia } from '../../hook/index.ts';
import { supportsWebp } from '../../utils/supportWebp';

const BannerWrapper = styled.div`
  position: relative;
  height: 696px;
  width: 100%;
  background-color: #313131;
  top: 0;
  padding-top: 1px;
  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    height: 460px;
  }
`;

const ImageStyle = styled(Img)``;

const TeamBannerDetail = styled.div`
  position: absolute;
  top: 270px;
  left: 0;
  right: 0;
  color: ${({ theme }) => theme.color.textWhite};
  margin: auto;
  max-width: 1020px;

  @media screen and (max-width: 1170px) {
    max-width: 1120px;
    padding: 0 100px;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    top: 180px;
    display: block;
    ${({ theme }) => theme.typography.titleBar()};
    text-align: -webkit-center;
    padding: 0;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.footerSubHeadline()};
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 0 10%;
    h1 {
      ${({ theme }) => theme.typography.titleBar()};
    }
  }
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.link()};
  line-height: 27px;
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    p {
      ${({ theme }) => theme.typography.formHeadline()};
    }
  }
`;
const BannerTeam = ({ title, image, desc, desc_tablet, desc_mobile, translateY }) => {
  const isBigSize = useMedia(`(min-width: 1113px)`);
  const isTabletSize = useMedia(`(min-width: 426px) and (max-width: 1112px)`);
  const isSmallSize = useMedia(`(max-width: 425px)`);
  const [isSupportWebP, setIsSupportWebP] = useState(false);

  useEffect(() => {
    supportsWebp().then((res) => setIsSupportWebP(res));
  }, []);

  return (
    <>
      <BannerWrapper isSupportWebP={isSupportWebP}>
        <div
          css={`
            width: 100%;
            background-color: #313131;
            height: 500px;
            position: fixed !important;
          `}
        >
          <div
            css={`
            z-index: 1;
            height: 696px !important;
            max-width: 840px !important;
            width: 100% !important;
            float: right !important;
            position: fixed !important;
            right: 0 !important;
            transform: translateY(${translateY}px)} !important;
            object-fit: cover !important;
            background: radial-gradient(108.33% 200% at 100% 100%, rgba(48, 48, 48, 0) 0%, rgb(49, 49, 49) 100%),url(.jpg);

            @media screen and (max-width: 425px) {
              height: 460px !important;
              object-fit: cover !important;
              object-position: right !important;
            }
          `}
          />
          <ImageStyle
            fluid={image}
            css={`
          height: 696px !important;
          max-width: 840px !important;
          width: 100% !important;
          float: right !important;
          position: fixed !important;
          right: 0 !important;
          transform: translateY(${translateY}px)} !important;
          object-fit: cover !important;

          @media screen and (max-width: 425px) {
            height: 460px !important;
            object-fit: cover !important;
            object-position: right !important;
          }
        `}
          />
        </div>
        <TeamBannerDetail>
          <Title>{title}</Title>
          {isBigSize ? <Description>{desc}</Description> : null}
          {isTabletSize ? <Description>{desc_tablet}</Description> : null}
          {isSmallSize ? <Description>{desc_mobile}</Description> : null}
        </TeamBannerDetail>
      </BannerWrapper>
    </>
  );
};

const BannerPropTypes = {
  image: PropTypes.object,
  animate: PropTypes.bool,
  fadeIn: PropTypes.bool,
  isHomepage: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  button: PropTypes.bool,
  linkTo: PropTypes.string,
  linkLabel: PropTypes.string,
};

BannerTeam.propTypes = BannerPropTypes;

export default BannerTeam;
