import React from 'react';
import styled from 'styled-components';

// Utils
import AnimateComp from '../../../utils/animateWrapper';

const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  margin: 0 auto;
  max-width: 690px;
`;

const FootageWrapper = styled.div`
  color: ${({ theme }) => theme.color.textWhite};
  display: flex;
  justify-content: center;
  padding: 30px;
  padding-bottom: 85px;
  flex-flow: row wrap;
  text-align: center;
`;

const TitleWrapper = styled.div`
  ${({ theme }) => theme.typography.titleFootage()};
  font-style: italic;
  margin-bottom: 13px;
  width: 100%;

  animation-delay: 0.3s;
  animation-fill-mode: backwards;

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 60%;
    white-space: pre-wrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileM}) {
    width: 75%;
  }
`;

const DetailSpan = styled.div`
  width: 100%;
  animation-delay: 0.6s;
  animation-fill-mode: backwards;
`;

const DetailWrapper = styled.div`
  ${({ theme }) => theme.typography.footerTextContent()}
  width: 100%;

  &.tablet {
    display: none;
  }
  &.mobile {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.device.laptop}) {
    &.pc {
      display: none;
    }
    &.tablet {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.device.mobileL}) {
    &.pc {
      display: none;
    }
    &.tablet {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

const ProfileWrapper = styled.div`
  ${({ theme }) => theme.typography.smallTitle()}
  margin-top: 30px;
  width: 100%;
  animation-delay: 0.9s;
  animation-fill-mode: backwards;
`;

const SubDetailWrapper = styled.div`
  ${({ theme }) => theme.typography.smallDesc()}
  white-space: pre-line;
  width: 100%;

  animation-delay: 1.2s;
  animation-fill-mode: backwards;
`;

const Footage = ({ title, detail, profile, profile_detail, detail_tablet, detail_mobile }) => {
  return (
    <Wrapper>
      <FootageWrapper>
        <AnimateComp animationType="fadeIn" partVisible={false}>
          {({ className }) => <TitleWrapper className={className}>{title}</TitleWrapper>}
        </AnimateComp>
        <AnimateComp animationType="fadeIn" partVisible={false}>
          {({ className }) => (
            <DetailSpan className={className}>
              <DetailWrapper className="pc">{detail}</DetailWrapper>
              <DetailWrapper className="tablet">{detail_tablet}</DetailWrapper>
              <DetailWrapper className="mobile">{detail_mobile}</DetailWrapper>
            </DetailSpan>
          )}
        </AnimateComp>
        <AnimateComp animationType="fadeIn" partVisible={false}>
          {({ className }) => (
            <ProfileWrapper className={className}>
              <b>{profile}</b>
            </ProfileWrapper>
          )}
        </AnimateComp>
        <AnimateComp animationType="fadeIn" partVisible={false}>
          {({ className }) => (
            <SubDetailWrapper className={className}>{profile_detail}</SubDetailWrapper>
          )}
        </AnimateComp>
      </FootageWrapper>
    </Wrapper>
  );
};

export default Footage;
